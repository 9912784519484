<!-- 企业管理-企业列表-订单信息 -->
<template>
    <div>
        <single-search-panel :model="form" @search="handleSearch">
            <form-col v-bind="tabLayout" label="搜索" prop="riskControlNumber">
                <template slot="formElements">
                    <a-input v-model="form.riskControlNumber" placeholder="输入风控单号搜索" />
                </template>
            </form-col>
        </single-search-panel>
        <a-table
            :columns="columns"
            :data-source="dataList"
            :pagination="pagination"
            :loading="loading"
            :scroll="scrollConfig"
            row-key="id"
            @change="handleTableChange">
            <template slot="action" slot-scope="action">
                <a-button
                    v-auth="ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_ORDER_INFO_BUTTON"
                    type="link"
                    @click="handleDeatil(action)"
                >
                    查看
                </a-button>
            </template>
        </a-table>
    </div>
</template>
<script >
import {
    Button as AButton,
    Table as ATable,
    Input as AInput
} from 'ant-design-vue'
import SingleSearchPanel from '@components/SingleSearchPanel'
import FormCol from '@components/FormCol'

import { ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_ORDER_INFO_BUTTON } from '@constant/authEnum/enterprise'
import { DEFAULT_PAGES_SIZE } from '@config'

import tableShowTotal from '@mixins/tableShowTotal'
import getOrderListService from '@service/enterprise/getOrderListService'
import enumOperate from '@mixins/enumOperate'
import { BUSINESS_TYPE_MAP } from '@constant/enum'

const columns = [
    {
        title: '风控单号',
        dataIndex: 'riskControlNumber',
        align: 'left',
        key: 'riskControlNumber',
        width: 200,
        fixed: 'left'
    },
    {
        title: '业务类型',
        dataIndex: 'riskBusinessType',
        align: 'left',
        key: 'riskBusinessType',
        width: 200,
        customRender: v => BUSINESS_TYPE_MAP[v]
    },
    {
        title: '产品分类',
        dataIndex: 'riskCategoryName',
        align: 'left',
        key: 'riskCategoryName',
        width: 300
    },
    {
        title: '投保人',
        dataIndex: 'policyHolder',
        align: 'left',
        key: 'policyHolder',
        width: 260
    },
    {
        title: '项目名称',
        dataIndex: 'projectName',
        align: 'left',
        key: 'projectName',
        width: 200
    },
    {
        title: '保险金额',
        dataIndex: 'insuranceAmount',
        align: 'left',
        key: 'insuranceAmount',
        width: 200
    },
    {
        title: '保费',
        dataIndex: 'premium',
        align: 'left',
        key: 'premium',
        width: 200
    },
    {
        title: '提交时间',
        dataIndex: 'createDateTime',
        align: 'left',
        key: 'createDateTime',
        width: 200
    },
    {
        title: '订单状态',
        dataIndex: 'stateValue',
        align: 'left',
        key: 'stateValue',
        width: 200
    },
    {
        title: '订单来源',
        dataIndex: 'orderSourceDesc',
        align: 'left',
        key: 'orderSourceDesc',
        width: 200
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        width: 150,
        fixed: 'right'
    }
]

export default {
    name: 'OrderInformationPanel',
    components: {
        AButton,
        ATable,
        SingleSearchPanel,
        FormCol,
        AInput
    },
    mixins: [tableShowTotal, enumOperate],
    props: {
        tabLayout: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            columns,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100,
                y: 500
            },
            ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_ORDER_INFO_BUTTON,
            form: {
                riskControlNumber: ''
            }
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        id() {
            return this.$route.query.id
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params, id } = this
                const param = {
                    current,
                    size: limit,
                    ...params,
                    enterpriseId: id
                }
                const result = await getOrderListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        handleExport() {},
        handleDeatil(action) {
            const { riskControlNumber } = action
            this.$router.push({
                name: 'OrderListInfo',
                query: {
                    riskControlNumber
                }
            })
        }
    }
}
</script>
<style lang="scss">
</style>
