<!-- 企业详情-财报信息详情-现金流量表 -->
<template>
    <a-spin
        :spinning="loading"
        class="cash-flow-statement-panel">
        <div class="common-title">现金流量表</div>
        <a-descriptions layout="horizontal" :column="2" class="common-description">
            <a-descriptions-item label="企业名称">{{ info.enterpriseName }}</a-descriptions-item>
            <a-descriptions-item label="单位">元</a-descriptions-item>
        </a-descriptions>
        <a-row v-if="queryList.length"
               type="flex"
               class="common-row">
            <a-col flex="450px">
                <div class="cash-flow-statement-panel__thead cash-flow-statement-panel__border-bottom">科目</div>
                <div class="cash-flow-statement-panel__thead cash-flow-statement-panel__border-bottom">项目</div>
                <div v-for="item in queryList[0].queryList" :key="item.coding">
                    <div
                        :flex="1"
                        class="cash-flow-statement-panel__border-bottom">
                        {{ item.name }}
                    </div>
                </div>
            </a-col>
            <a-col v-for="(item,index) in queryList" :key="item.coding" :flex="1">
                <div class="cash-flow-statement-panel__border-bottom cash-flow-statement-panel__thead">
                    {{ showYearText(queryList[index].reportDate) }}
                </div>
                <div class="cash-flow-statement-panel__border-bottom cash-flow-statement-panel__thead">
                    本期金额
                </div>
                <div
                    v-for="item2 in item.queryList"
                    :key="item2.coding"
                    style="line-height: 44px"
                    class="cash-flow-statement-panel__border-bottom">
                    {{ item2.currentPeriod }}
                </div>
            </a-col>
        </a-row>
    </a-spin>
</template>
<script>
import {
    Descriptions as ADescriptions,
    Spin as ASpin,
    Row as ARow,
    Col as ACol
} from 'ant-design-vue'

import empty from '@mixins/empty'
import getCashFlowSheetService from '@service/enterprise/getCashFlowSheetService'

export default {
    name: 'CashFlowStatementPanel',
    components: {
        ASpin,
        ARow,
        ACol,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    mixins: [empty],
    data() {
        return {
            info: {},
            queryList: [],
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100
            }
        }
    },
    computed: {
        id() {
            return this.$route.query.id
        },
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        showYearText(date) {
            if (date.includes('.')) {
                const [year, mon] = date.split('.')
                return `${year}年${mon}月`
            } else {
                return `${date}年`
            }
        },
        async getList() {
            const { riskControlNumber } = this
            try {
                this.loading = true
                this.info = await getCashFlowSheetService({
                    riskControlNumber
                })
                this.queryList = this.info.queryList || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.cash-flow-statement-panel {
    .ant-col {
        text-indent: 25px;
        line-height: 44px;
    }

    &__thead {
        background-color: $custom-table-title-background-color;
        line-height: 44px;
    }

    .ant-table-wrapper {
        margin-bottom: 35px;
    }

    div {
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    &__border-bottom {
        border-bottom: 1px solid #e8e8e8;
    }
}
</style>
