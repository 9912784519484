var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',{ref:"ruleForm",staticClass:"grade-factor-section-type",attrs:{"model":_vm.form}},[_c('custom-button',{attrs:{"text":"插入字段","type":"reset"},on:{"click":_vm.handleInsert}}),_c('div',{staticStyle:{"overflow-x":"scroll"}},[_c('table',[_c('thead',{staticClass:"grade-factor-section-type__thead"},[_c('tr',_vm._l((_vm.sectionTypeTab),function(item){return _c('th',{key:item.name,staticClass:"ant-table-align-center"},[_c('div',{style:({width:item.width+'px',textAlign:item.align,padding:'0 20px'})},[_vm._v(_vm._s(item.name))])])}),0)]),_c('tbody',{staticClass:"grade-factor-basic-type"},_vm._l((_vm.form.scoringFactorDetails),function(item,index){return _c('tr',{key:item.$id},[_c('td',[_c('a-form-model-item',{staticStyle:{"width":"60px"}},[_vm._v(_vm._s(index + 1))])],1),_c('td',[_c('a-form-model-item',{staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.showAttributeText(item)))])],1),_c('td',[_c('a-form-model-item',{attrs:{"prop":`scoringFactorDetails[${index}].score`,"rules":_vm.negativeAttributeRules}},[_c('a-input',{staticStyle:{"width":"100px"},attrs:{"placeholder":"输入分值"},model:{value:(item.score),callback:function ($$v) {_vm.$set(item, "score", $$v)},expression:"item.score"}})],1)],1),_c('td',[_c('div',{staticClass:"grade-factor-section-type__score"},[_c('a-form-model-item',{attrs:{"prop":`scoringFactorDetails[${index}].minValueComparator`,"rules":{
                                    required: true,
                                    message: '请选择',
                                    trigger: 'blur',
                                }}},[_c('min-comparator',{model:{value:(item.minValueComparator),callback:function ($$v) {_vm.$set(item, "minValueComparator", $$v)},expression:"item.minValueComparator"}})],1),_c('a-form-model-item',{attrs:{"prop":`scoringFactorDetails[${index}].min`,"rules":_vm.negativeAttributeRules}},[_c('a-input-number',{staticClass:"grade-factor-section-type__input-number",attrs:{"max":_vm.MAX_VALUE,"placeholder":"输入分值"},model:{value:(item.min),callback:function ($$v) {_vm.$set(item, "min", $$v)},expression:"item.min"}})],1),_c('a-form-model-item',[_vm._v("~")]),_c('a-form-model-item',{attrs:{"rules":{
                                    required: true,
                                    message: '请选择',
                                    trigger: 'blur',
                                },"prop":`scoringFactorDetails[${index}].maxValueComparator`}},[_c('max-comparator',{attrs:{"disabled":_vm.isDisabled(item)},model:{value:(item.maxValueComparator),callback:function ($$v) {_vm.$set(item, "maxValueComparator", $$v)},expression:"item.maxValueComparator"}})],1),_c('a-form-model-item',{attrs:{"prop":`scoringFactorDetails[${index}].max`,"rules":_vm.negativeAttributeRules}},[_c('a-input-number',{staticClass:"grade-factor-section-type__input-number",attrs:{"disabled":_vm.isDisabled(item),"max":_vm.MAX_VALUE,"placeholder":"输入分值"},model:{value:(item.max),callback:function ($$v) {_vm.$set(item, "max", $$v)},expression:"item.max"}})],1)],1)]),_c('td',[_c('a-form-model-item',[_c('custom-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isDisabled(item)),expression:"!isDisabled(item)"}],attrs:{"text":"删除","type":"reset"},on:{"click":function($event){return _vm.handleDelete(index)}}})],1)],1)])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }