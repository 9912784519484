<!-- 企业详情-基本信息-基本信息 -->
<template>
    <a-descriptions bordered :column="2" class="enterprise-basic-information-panel">
        <a-descriptions-item label="企业名称">{{ info.name }}</a-descriptions-item>
        <a-descriptions-item label="统一社会信用代码">{{ info.socialCreditCode }}</a-descriptions-item>
        <a-descriptions-item label="注册时间">{{ info.registrationTime }}</a-descriptions-item>
        <a-descriptions-item label="营业期限">{{ info.businesSterm }}</a-descriptions-item>
        <a-descriptions-item label="注册资本（元）">{{ info.registeredCapital }}</a-descriptions-item>
        <a-descriptions-item label="企业类型">{{ info.type }}</a-descriptions-item>
        <a-descriptions-item label="企业性质">{{ info.economicNature }}</a-descriptions-item>
        <a-descriptions-item label="股东结构">{{ info.shareholderStructure }}</a-descriptions-item>
        <a-descriptions-item label="注册地址">{{ info.registeredAddress }}</a-descriptions-item>
        <a-descriptions-item label="是否上市">{{ isList }}</a-descriptions-item>
    </a-descriptions>
</template>

<script>
import { Descriptions as ADescriptions } from 'ant-design-vue'
import empty from '@mixins/empty'

export default {
    name: 'EnterpriseBasicInformationPanel',
    components: {
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    mixins: [empty],
    props: {
        info: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        isList() {
            return this.info.isList ? '是' : '否'
        }
    },
    methods: {}
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.enterprise-basic-information-panel {
  .ant-descriptions-item-label {
    width: $descriptions-item-label-width;
  }
}
</style>
