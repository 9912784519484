<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="布局分类名称" prop="name">
            <a-input v-model="form.name" placeholder="请输入布局分类" :max-length="INPUT_MAX_LENGTH" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel,
    input as AInput
} from 'ant-design-vue'

import { filterParams } from '@utils/search'
import { ERROR_COMMIT_SUCCESS, ERROR_COMMIT_EDIT } from '@constant/error'
import { DATA_CHECK_MODEL_LAYOUT_TYPE } from '@constant/enum'
import addLayoutCategoryService from '@service/ruleEngine/DataCheckSetting/addLayoutCategoryService'
import editLayoutCategoryService from '@service/ruleEngine/DataCheckSetting/editLayoutCategoryService'
import { INPUT_MAX_LENGTH } from '@constant/Const'
export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        mode: {
            type: String,
            default: 'create'
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            form: {
                name: '',
                layoutType: DATA_CHECK_MODEL_LAYOUT_TYPE,
                objectId: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入分类'
                    }
                ]
            }
        }
    },
    computed: {
        queryId() {
            return this.$route.query.id
        },
        isAdd() {
            return this.mode === 'create'
        },
        commitFn() {
            const { isAdd } = this
            return isAdd ? addLayoutCategoryService : editLayoutCategoryService
        },
        commitMsg() {
            const { isAdd } = this
            return isAdd ? ERROR_COMMIT_SUCCESS : ERROR_COMMIT_EDIT
        }
    },
    watch: {
        mode: {
            handler() {
                if (this.isAdd) return
                const { info } = this
                this.form.name = info.name
                this.form.id = info.id
            },
            immediate: true
        }
    },
    methods: {
        async handleSubmit() {
            try {
                const { commitFn, commitMsg } = this
                this.form.objectId = this.queryId // 主体ID
                this.$emit('loading', true)
                await commitFn(filterParams(this.form))
                this.$message.success(commitMsg)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
