<template>
    <container-panel :no-card-body-padding-top="true">
        <div slot="title">评分因子</div>
        <custom-button
            v-show="canAdd"
            slot="button"
            v-auth="RULE_ENGINE_GRADE_MODEL_INFO_ADD_GRADE_FACTOR_MENU_CODE"
            text="添加评分因子"
            type="reset"
            @click="handleAddOrEdit({})" />
        <!-- 表格 start -->
        <template slot="content" slot-scope="">
            <div>
                搜索：
                <a-input-search
                    v-model="query"
                    placeholder="输入评分因子名称进行搜索"
                    style="width: 270px; margin-bottom: 15px"
                    @search="onSearch" />
            </div>
            <a-table :columns="columns"
                     :data-source="dataList"
                     :pagination="pagination"
                     :loading="loading"
                     row-key="id"
                     @change="handleTableChange">
                <!-- 操作 -->
                <template
                    v-if="canAction"
                    slot="action"
                    slot-scope="text,action">
                    <a-button v-auth="RULE_ENGINE_GRADE_MODEL_INFO_EDIT_GRADE_FACTOR_BUTTON"
                              type="link"
                              @click="handleAddOrEdit(action)">
                        编辑
                    </a-button>
                    <a-button v-auth="RULE_ENGINE_GRADE_MODEL_INFO_DELETE_GRADE_FACTOR_BUTTON"
                              type="link"
                              @click="handleDelete(action)">
                        移除
                    </a-button>
                </template>
            </a-table>
        </template>
    <!-- 表格 end -->
    </container-panel>
</template>

<script>
import { Button as AButton, Input as AInput } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
// import { DEFAULT_PAGES_SIZE } from '@config'
import {
    RULE_ENGINE_GRADE_MODEL_INFO_DELETE_GRADE_FACTOR_BUTTON,
    RULE_ENGINE_GRADE_MODEL_INFO_EDIT_GRADE_FACTOR_BUTTON
} from '@constant/authEnum/ruleEngine'
import {
    RULE_ENGINE_GRADE_MODEL_INFO_ADD_GRADE_FACTOR_MENU_CODE
} from '@constant/menu_code'
import CustomButton from '@components/Button'

import getGradeFactorListService from '@service/ruleEngine/GradeModel/getGradeFactorListService'
import deleteGradeFactorService from '@service/ruleEngine/GradeModel/deleteGradeFactorService'
import { ERROR_COMMIT_DELETE } from '@constant/error'
import showModelButton from '@utils/showModelButton'
import { accMul } from '@utils/calculate'
import initTable from '@mixins/initTable'
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        customRender: (v, r, idx) => ++idx,
        key: 'index'
    },
    {
        title: '风险对象',
        dataIndex: 'masterDataName',
        align: 'left',
        key: 'masterDataName'
    },
    {
        title: '风险分类',
        dataIndex: 'masterDataClassificationName',
        align: 'left',
        key: 'masterDataClassificationName'
    },
    {
        title: '评分因子',
        dataIndex: 'factorName',
        align: 'left',
        key: 'factorName'
    },
    {
        title: '权重',
        dataIndex: 'weight',
        align: 'left',
        key: 'weight',
        customRender: v => v ? accMul(v) + '%' : '-'
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'RiskFactorTabPanel',
    components: {
        CustomButton,
        ContainerPanel,
        AButton,
        AInputSearch: AInput.Search
    },
    mixins: [initTable],
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            accMul,
            query: '',
            loading: false,
            RULE_ENGINE_GRADE_MODEL_INFO_DELETE_GRADE_FACTOR_BUTTON,
            RULE_ENGINE_GRADE_MODEL_INFO_EDIT_GRADE_FACTOR_BUTTON,
            RULE_ENGINE_GRADE_MODEL_INFO_ADD_GRADE_FACTOR_MENU_CODE
        }
    },
    computed: {
        queryId() {
            return this.$route.query.id
        },
        columns() {
            const { canAction } = this
            if (canAction) {
                return columns
            } else {
                const len = columns.length
                return columns.slice(0, len - 1)
            }
        },
        // 可操作
        canAction() {
            const { isLook, info } = this
            const canAction = showModelButton.canEdit(info.auditState)
            return !isLook && canAction
        },
        // 可添加
        canAdd() {
            const { isLook, info } = this
            return !isLook && showModelButton.canAdd(info.auditState)
        }
    },
    methods: {
        onSearch() {
            this.getList()
        },
        handleAddOrEdit({ id }) {
            const { queryId, info } = this
            this.$router.push({
                name: 'AddOrEditGradeFactor',
                query: {
                    queryId,
                    id,
                    isWeight: info.isWeight
                }
            })
        },
        handleDelete({ id }) {
            this.$confirm({
                title: '确认移除当前评分因子吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteService(id)
                },
                onCancel() {
                }
            })
        },
        async deleteService(id) {
            try {
                await deleteGradeFactorService(id)
                this.getList()
                this.$message.success(ERROR_COMMIT_DELETE)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, queryId, query } = this
                const res = await getGradeFactorListService({
                    current,
                    size: limit,
                    scoringModelId: queryId,
                    query
                })
                this.dataList = res.records
                this.total = res.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">

</style>
