<!-- 企业详情-财报信息详情-现金流量表 -->
<template>
    <div>
        <a-spin
            v-if="info"
            :spinning="loading"
            class="financial-analysis-statement-panel">
            <div class="common-title">财务指标分析表</div>
            <a-descriptions layout="horizontal" :column="2" class="common-description">
                <a-descriptions-item label="企业名称">{{ info.enterpriseName }}</a-descriptions-item>
                <a-descriptions-item label="单位">元</a-descriptions-item>
            </a-descriptions>
            <div class="common-row">
                <table class="table">
                    <colgroup>
                        <col style="width: 150px; min-width: 150px;">
                        <col style="width: 100px; min-width: 100px;">
                        <col style="width: 300px; min-width: 300px;">
                        <col style="width: 200px; min-width: 300px;">
                        <col style="width: 200px; min-width: 200px;">
                    </colgroup>
                    <tr>
                        <th />
                        <th>{{ financialFromValuePdf.sort }}</th>
                        <th>{{ financialFromValuePdf.name }}</th>
                        <th>{{ financialFromValuePdf.firstValue }}</th>
                        <th>{{ financialFromValuePdf.secondValue }}</th>
                    </tr>
                    <tbody v-for="(item,index) in queryList" :key="index">
                        <tr v-for="(item2,index2) in item" :key="item2.sort">
                            <td v-if="index2===0" :rowSpan="9" class="td-rowspan">{{ keyNameObj[++index] }}</td>
                            <td>{{ item2.sort }}</td>
                            <td>{{ item2.name }}</td>
                            <td>{{ item2.firstValue }}</td>
                            <td>{{ item2.secondValue }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </a-spin>
        <a-empty v-else style="margin: 30px auto" />
    </div>
</template>
<script>
import {
    Descriptions as ADescriptions,
    Spin as ASpin, Empty as AEmpty
} from 'ant-design-vue'

import empty from '@mixins/empty'
import getFinancialAnalysisService from '@service/enterprise/getFinancialAnalysisService'

export default {
    name: 'CashFlowStatementPanel',
    components: {
        ASpin,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        AEmpty
    },
    mixins: [empty],
    data() {
        return {
            financialFromValuePdf: {},
            info: {},
            queryList: [],
            loading: false,
            keyNameObj: {
                1: '偿债能力',
                2: '经营能力',
                3: '盈利能力',
                4: '发展能力'
            }
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        async getList() {
            const { riskControlNumber } = this
            try {
                this.loading = true
                this.info = await getFinancialAnalysisService({
                    riskControlNumber
                })
                if (!this.info) return
                this.financialFromValuePdf = this.info.financialFromValuePdf
                this.queryList = Object.values(this.info.treeMap) || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.financial-analysis-statement-panel {
    .table {
        width: 100%;

        td,
 th {
            border-bottom: 1px solid $border-color;
            text-align: center;
            line-height: 44px;
        }

        th {
            background-color: $custom-table-title-background-color;
        }

        .td-rowspan {
            border-right: 1px solid $border-color;
        }
    }
}
</style>
