<template>
    <div class="clearfix">
        <a-upload
            v-if="!value"
            v-bind="props"
            v-on="listeners">
            <a-button type="link"> {{ text }}</a-button>
        </a-upload>
        <div v-else style="cursor: pointer" @click="handlePreview">
            <img v-if="showImg" alt="example" :style="{width: imgWidth+'px',height: imgWidth+'px'}" :src="value">
            <template v-else>
                <i class="anticon anticon-paper-clip" /><span>{{ file.name }}</span>
            </template>
            <a-button type="link"
                      icon="delete"
                      @click.stop="handleDelete" />
        </div>
        <!--    pdf预览 -->
        <iframe-modal
            :url="value"
            :visible="previewVisible"
            @cancel="previewVisible=false" />
        <!--    图片预览 -->
        <a-modal :visible="imgVisible" :footer="null" @cancel="imgVisible=false">
            <img alt="example" style="width: 100%" :src="value">
        </a-modal>
    </div>
</template>

<script>
import {
    Upload as AUpload,
    Button as AButton
} from 'ant-design-vue'
import mixins from './mixins'
import { getFileExt, IS_IMG_ENUM_LIST, isImg, isPdf } from '@utils/file'
import { OSS_PACKAGE_TYPE_RULE_ENGINE_TYPE } from '@constant/enum'
import { OSS_PACKAGE_TYPE_RULE_ENGINE_MAX_SIZE } from '@constant/Const'

export default {
    name: 'UploadInTable',
    components: {
        AUpload,
        AButton,
        IframeModal: () => import('@weights/IframeModal')
    },
    mixins: [mixins],
    props: {
        // 图片地址
        value: {
            type: String,
            default: ''
        },
        text: {
            type: [String],
            default: '上传图片'
        },
        // 显示图片
        showImg: {
            type: Boolean,
            default: false
        },
        imgWidth: {
            type: Number,
            default: 50
        },
        ossType: {
            type: Number,
            default: OSS_PACKAGE_TYPE_RULE_ENGINE_TYPE
        },
        size: {
            type: Number,
            default: OSS_PACKAGE_TYPE_RULE_ENGINE_MAX_SIZE
        },
        // 支持上传的文件格式数组
        acceptList: {
            type: Array,
            default() {
                return IS_IMG_ENUM_LIST
            }
        }
    },
    data() {
        return {
            imgVisible: false,
            previewVisible: false,
            previewImage: '',
            templateFileList: [],
            file: {}
        }
    },
    methods: {
        handlePreview() {
            const txt = getFileExt(this.file.name)
            if (isImg(txt)) {
                this.imgVisible = true
            } else if (isPdf) {
                this.previewVisible = true
            } else {
                window.open(this.value)
            }
        },
        handleDelete() {
            this.$emit('input', '')
            this.$emit('delete')
        }
    }
}
</script>

<style lang="scss">
</style>
