<template>
    <a-cascader
        v-model="areaValue"
        change-on-select
        :options="dataList"
        :show-search="{ filter }"
        placeholder="请选择地区"
        @blur="handleBlur"
    />
</template>

<script>

import { Cascader as ACascader } from 'ant-design-vue'
import { COUNTRY_CODE } from '@constant/Const'
import { getAreaData, getMapAndTree } from '@utils/toTree'
export default {
    name: 'AreaCascader',
    components: {
        ACascader
    },
    props: {
        value: {
            type: Array,
            default() {
                return []
            }
        },
        // 是否显示'国家'
        showCounty: {
            type: Boolean,
            default: true
        },
        // 是否过滤区
        isFilter: {
            type: Boolean,
            default: false
        },
        initArea: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dataList: [],
            mapArea: null
        }
    },
    computed: {
        areaValue: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        filter(inputValue, path) {
            return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
        },
        async getList() {
            try {
                const { showCounty, isFilter } = this
                let data = await getAreaData()
                if (isFilter) {
                    data = this.filterArea(data)
                }
                if (!showCounty) {
                    data = data.filter(item => item.value !== COUNTRY_CODE)
                }

                // 树形转map
                const { tree, map } = getMapAndTree(data)
                this.dataList = tree
                this.mapArea = map
                this.$emit('area-finish', this.dataList, this.mapArea)
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleBlur() {
            this.$emit('blur')
        },
        /**
     * 根据value找到name
     * */
        getName(value, name = []) {
            const { mapArea } = this
            const item = mapArea.get(value)
            name.unshift(item.label)
            if (item.parent) {
                this.getName(item.parentId, name)
            }
            return name
        },
        /**
     * 获取所有子集
     */
        getChildIds(value, childIds) {
            const { mapArea } = this
            const item = mapArea.get(value)
            if (item && item.children) {
                item.children.forEach(item2 => {
                    childIds.push(item2.value)
                    this.getChildIds(item2.value, childIds)
                })
            }
        },
        /**
     * 取省市数据 过滤区
     * */
        filterArea(arr) {
            let res = []
            arr.forEach(item => {
                if (item.children) {
                    item.children = item.children.map(item2 => {
                        // eslint-disable-next-line no-unused-vars
                        const { children, ...target } = item2
                        return target
                    })
                }
                res.push(item)
            })
            return res
        }
    }
}
</script>

<style lang='scss'>

</style>
