<template>
    <!--  提交订单-项目类型 -->
    <a-select
        v-model="modelValue"
        option-label-prop="label"
        @blur="onBlur">
        <a-select-option
            v-for="item in dataList"
            :key="item.value"
            :value="item.value"
            :label="item.name">
            {{ item.name }}
        </a-select-option>
    </a-select>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'
import getListDictionaryService from '@service/gbCloud/getListDictionaryService'

export default {
    name: 'ProjectType',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: {
            type: [String, Number]
        }
    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        onBlur() {
            this.$emit('blur')
        },
        async getList() {
            try {
                this.dataList = await getListDictionaryService({
                    dictionaryCategoryId: 'e098d282-d173-4cdf-a420-a3bf4310c076',
                    dataType: 'DICT',
                    resultType: 1,
                    source: 2
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
