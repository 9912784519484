<!-- 投保人财务信息列表 -->
<template>
    <div class="financial-list-panel">
        <div class="financial-list-panel__list">
            <div>
                财务报表
                <a-switch
                    v-show="!isLook"
                    style="margin-left: 10px"
                    :checked="isSwitch"
                    @change="value => handleSwitch(value)" />
            </div>
            <a-space style="float: right">
                <a-button
                    v-show="showCopyButton"
                    v-auth="RISK_CONTROL_INFO_COPY_FINANCIAL_BUTTON"
                    type="primary"
                    @click="handleCopy">
                    获取财报
                </a-button>
                <a-button
                    v-show="!isLook"
                    v-auth="RISK_CONTROL_INFO_ADD_FINANCIAL_BUTTON"
                    type="primary"
                    @click="handleAdd">
                    添加
                </a-button>
            </a-space>
        </div>
        <a-table :columns="columns"
                 :data-source="dataList"
                 :loading="loading"
                 row-key="reportDate"
                 @change="handleTableChange">
            <template slot="sort" slot-scope="text, action,index">{{ index + 1 }}</template>
            <template slot="isAudit" slot-scope="text">
                {{ getApiEnumLabelFromList(FINANCE_REPORT_AUDIT_ENUM_LIST, text) }}
            </template>
            <template slot="action" slot-scope="text, action">
                <a-space>
                    <a-button
                        v-auth="RISK_CONTROL_INFO_LOOK_FINANCIAL_BUTTON"
                        type="link"
                        @click="handleLook(action)">
                        查看
                    </a-button>
                    <a-button
                        v-show="!isLook"
                        v-auth="RISK_CONTROL_INFO_EDIT_FINANCIAL_BUTTON"
                        type="link"
                        @click="handleEdit(action)">
                        修改
                    </a-button>
                    <a-button
                        v-show="!isLook"
                        v-auth="RISK_CONTROL_INFO_DELETE_FINANCIAL_BUTTON"
                        type="link"
                        @click="handleDelete(action)"
                    >
                        删除
                    </a-button>
                </a-space>
            </template>
        </a-table>
    </div>
</template>

<script>
import { Table as ATable, Space as ASpace, Button as AButton, Switch as ASwitch } from 'ant-design-vue'
import { DEFAULT_PAGES_SIZE } from '@config'
import getFinancialReportListService from '@service/task/getFinancialReportListService'
import enableFinancialReportService from '@service/task/enableFinancialReportService'
import copyEnterpriseFinanceService from '@service/task/copyEnterpriseFinanceService'
import deleteFinancialReportService from '@service/task/deleteFinancialReportService'

import tableShowTotal from '@mixins/tableShowTotal'

import {
    RISK_CONTROL_INFO_ADD_FINANCIAL_BUTTON, RISK_CONTROL_INFO_COPY_FINANCIAL_BUTTON,
    RISK_CONTROL_INFO_LOOK_FINANCIAL_BUTTON, RISK_CONTROL_INFO_EDIT_FINANCIAL_BUTTON,
    RISK_CONTROL_INFO_DELETE_FINANCIAL_BUTTON
} from '@constant/authEnum/task'
import { ERROR_COMMIT_DELETE, ERROR_COMMIT_OPEN, ERROR_COMMIT_CLOSE } from '@constant/error'
import { FINANCE_REPORT_AUDIT_ENUM_LIST } from '@constant/enum'
import enumOperate from '@mixins/enumOperate'

const columns = [
    {
        title: '序号',
        key: 'sort',
        scopedSlots: {
            customRender: 'sort'
        },
        align: 'left'
    },
    {
        title: '报表日期',
        dataIndex: 'reportDate',
        align: 'left',
        key: 'reportDate',
        scopedSlots: {
            customRender: 'reportDate'
        }
    },
    {
        title: '是否审计',
        dataIndex: 'isAudit',
        align: 'left',
        key: 'isAudit',
        scopedSlots: {
            customRender: 'isAudit'
        }
    },
    {
        title: '操作',
        dataIndex: 'action',
        align: 'left',
        key: 'action',
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'FinancialListPanel',
    components: {
        AButton,
        ASpace,
        ATable,
        ASwitch
    },
    mixins: [tableShowTotal, enumOperate],
    props: {
        financialList: {
            type: Array,
            default() {
                return []
            }
        },
        currentStep: {
            type: Number,
            default: 0
        },
        isLook: {
            type: Boolean,
            default: false
        },
        showCopy: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isSwitch: false,
            RISK_CONTROL_INFO_ADD_FINANCIAL_BUTTON,
            RISK_CONTROL_INFO_COPY_FINANCIAL_BUTTON,
            RISK_CONTROL_INFO_LOOK_FINANCIAL_BUTTON,
            RISK_CONTROL_INFO_EDIT_FINANCIAL_BUTTON,
            RISK_CONTROL_INFO_DELETE_FINANCIAL_BUTTON,
            FINANCE_REPORT_AUDIT_ENUM_LIST,
            columns,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        // 无列表数据 展示此按钮
        showCopyButton() {
            const { showCopy, dataList } = this
            return showCopy && dataList.length === 0
        }
    },
    created() {
        this.getList()
    },
    methods: {
        async handleSwitch(val) {
            const { riskControlNumber } = this
            const message = val ? ERROR_COMMIT_OPEN : ERROR_COMMIT_CLOSE
            try {
                await enableFinancialReportService({
                    riskControlNumber,
                    isHaveFinancialReport: val
                })
                this.isSwitch = val
                this.$message.success(message)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async handleCopy() {
            const { riskControlNumber } = this
            try {
                await copyEnterpriseFinanceService({
                    riskControlNumber
                })
                await this.getList()
                if (this.dataList.length) {
                    this.$message.success('财报获取成功')
                } else {
                    this.$message.warning('当前投保人无历史财报')
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        handleAdd() {
            const { riskControlNumber } = this
            this.$router.push({
                name: 'AddFinancialReport',
                query: {
                    riskControlNumber
                }
            })
        },
        handleLook(action) {
            const { riskControlNumber } = this
            const { reportDate } = action
            this.$router.push({
                name: 'AddFinancialReport',
                query: {
                    reportDate,
                    riskControlNumber
                }
            })
        },
        handleEdit(action) {
            const { riskControlNumber } = this
            const { reportDate } = action
            this.$router.push({
                name: 'AddFinancialReport',
                query: {
                    reportDate,
                    id: 1,
                    riskControlNumber
                }
            })
        },
        handleDelete(action) {
            this.$confirm({
                title: '确认删除当前财务报表吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteService(action)
                },
                onCancel() {
                }
            })
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async deleteService(action) {
            try {
                const { reportDate } = action
                const { riskControlNumber } = this
                await deleteFinancialReportService({
                    reportDate,
                    riskControlNumber
                })
                this.getList()
                this.$message.success(ERROR_COMMIT_DELETE)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, riskControlNumber } = this
                const param = {
                    current,
                    size: limit,
                    riskControlNumber
                }
                const res = await getFinancialReportListService(param)
                const { enableFinance, ipage } = res
                this.isSwitch = enableFinance
                this.dataList = ipage.records || []
                this.current = ipage.current
                this.total = ipage.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
.financial-list-panel {
    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 44px;
        height: 44px;
        background: #fafafa;
        border-radius: 4px 4px 0 0;
        padding: 0 16px;
        color: #0f0f0f;
        border-bottom: 1px solid #e8e8e8;
    }
}
</style>
