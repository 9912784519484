<template>
    <container-panel class="commodity-basic-info-container-panel">
        <descriptions-with-head title="基本信息">
            <a-descriptions :column="1" bordered>
                <a-descriptions-item label="商品名称">
                    {{ info.name }}
                </a-descriptions-item>
                <a-descriptions-item label="商品描述">
                    {{ empty(info.description) }}
                </a-descriptions-item>
                <a-descriptions-item label="业务类型">
                    {{ empty(BUSINESS_TYPE_MAP[info.riskBusinessType]) }}
                </a-descriptions-item>
                <a-descriptions-item label="产品分类">
                    {{ info.riskCategoryName }}
                </a-descriptions-item>
                <a-descriptions-item
                    v-for="item in info.specDTOList"
                    :key="item.productSpecId"
                    :label="item.productSpecName">
                    {{ item.attributeName }}
                </a-descriptions-item>
                <a-descriptions-item label="订单来源">
                    {{ ORDER_SOURCE_MAP[info.orderSource] }}
                </a-descriptions-item>
                <a-descriptions-item label="地区范围">
                    {{ info.areaName }}
                </a-descriptions-item>
            </a-descriptions>
        </descriptions-with-head>
    </container-panel>
</template>

<script>
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import ContainerPanel from '@components/ContainerPanel'
import { Descriptions as ADescriptions } from 'ant-design-vue'

import { BUSINESS_TYPE_MAP, ORDER_SOURCE_MAP } from '@constant/enum'
import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'
export default {
    name: 'BasicInfo',
    components: {
        ADescriptions,
        ContainerPanel,
        ADescriptionsItem: ADescriptions.Item,
        DescriptionsWithHead
    },
    mixins: [enumOperate, empty],
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            BUSINESS_TYPE_MAP,
            ORDER_SOURCE_MAP
        }
    },
    computed: {}
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.commodity-basic-info-container-panel {
    .ant-descriptions-item-label {
        width: $descriptions-item-label-width;
    }
}
</style>
