<!-- 数据核验模型-数据核验模型详情-风险因子 -->
<template>
    <container-panel :no-card-body-padding-top="true">
        <div slot="title">风险因子({{ total }})</div>
        <custom-button
            v-show="canAdd"
            slot="button"
            v-auth="RULE_ENGINE_DATA_CHECK_INFO_ADD_RISK_FACTOR_MENU_CODE"
            text="添加风险因子"
            type="reset"
            @click="handleAdd" />
        <!-- 表格 start -->
        <template slot="content" slot-scope="">
            <div>
                搜索：
                <a-input-search placeholder="输入评分因子名称进行搜索" style="width: 270px; margin-bottom: 15px" @search="onSearch" />
            </div>
            <a-table :columns="columns"
                     :data-source="dataList"
                     :pagination="pagination"
                     :loading="loading"
                     row-key="id"
                     @change="handleTableChange">
                <template slot="index"  slot-scope="text,action,index">
                    {{ index+1 }}
                </template>
                <!-- 操作 -->
                <template
                    v-if="canAction"
                    slot="action"
                    slot-scope="text,action">
                    <a-button v-auth="RULE_ENGINE_DATA_CHECK_MODEL_DELETE_RISK_FACTOR_BUTTON"
                              type="link"
                              @click="handleDelete(action)">
                        移除
                    </a-button>
                </template>
            </a-table>
        </template>
    <!-- 表格 end -->
    </container-panel>
</template>

<script>
// 组件
import { Button as AButton, Table as ATable, Input as AInput } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import { DEFAULT_PAGES_SIZE } from '@config'
import { RULE_ENGINE_DATA_CHECK_MODEL_DELETE_RISK_FACTOR_BUTTON } from '@constant/authEnum/ruleEngine'
import {
    RULE_ENGINE_DATA_CHECK_INFO_ADD_RISK_FACTOR_MENU_CODE
} from '@constant/menu_code'
import CustomButton from '@components/Button'
import showModelButton from '@utils/showModelButton'

import getDataCheckRiskFactorListService from '@service/ruleEngine/DataCheckSetting/getDataCheckRiskFactorListService'
import deleteDataCheckRiskFactorService from '@service/ruleEngine/DataCheckSetting/deleteDataCheckRiskFactorService'
import { ERROR_COMMIT_DELETE } from '@constant/error'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        key: 'index',
        scopedSlots: {
            customRender: 'index'
        }
    },
    {
        title: '布局分类',
        dataIndex: 'layoutName',
        align: 'left',
        key: 'layoutName'
    },
    {
        title: '风险对象',
        dataIndex: 'masterDataName',
        align: 'left',
        key: 'masterDataName'
    },
    {
        title: '风险分类',
        dataIndex: 'masterDataClassificationName',
        align: 'left',
        key: 'masterDataClassificationName'
    },
    {
        title: '风险因子',
        dataIndex: 'factorName',
        align: 'left',
        key: 'factorName'
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'RiskFactorTabPanel',
    components: {
        CustomButton,
        ContainerPanel,
        ATable,
        AButton,
        AInputSearch: AInput.Search
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            params: {},
            dataList: [],
            size: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            query: '', // 模糊查询
            RULE_ENGINE_DATA_CHECK_MODEL_DELETE_RISK_FACTOR_BUTTON,
            RULE_ENGINE_DATA_CHECK_INFO_ADD_RISK_FACTOR_MENU_CODE
        }
    },
    computed: {
        pagination() {
            const { total, size, current } = this
            return {
                showTotal: (total, range) => `第${range[0]}-${range[1]}条/总共${total}条`,
                current,
                total,
                pageSize: size,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        // 改模型id
        queryId() {
            return this.$route.query.id
        },
        columns() {
            const { canAction } = this
            if (canAction) {
                return columns
            } else {
                const len = columns.length
                return columns.slice(0, len - 1)
            }
        },
        // 可操作
        canAction() {
            const { isLook, info } = this
            const canAction = showModelButton.canEdit(info.auditState)
            return !isLook && canAction
        },
        // 可添加
        canAdd() {
            const { isLook, info } = this
            return !isLook && showModelButton.canAdd(info.auditState)
        }
    },
    created() {
        this.getList()
    },
    methods: {
        onSearch(val) {
            this.query = val
            this.getList()
        },
        handleAdd() {
            this.$router.push({
                name: 'AddRiskFactor',
                query: {
                    queryId: this.queryId
                }
            })
        },
        handleDelete({ id }) {
            this.$confirm({
                title: '确认移除当前风险因子吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteService(id)
                },
                onCancel() {
                }
            })
        },
        async deleteService(id) {
            try {
                await deleteDataCheckRiskFactorService(id)
                this.getList()
                this.$message.success(ERROR_COMMIT_DELETE)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, size, queryId, query } = this
                const res = await getDataCheckRiskFactorListService({
                    current,
                    size,
                    dataVerificationModelId: queryId,
                    query
                })
                this.dataList = res.records
                this.total = res.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">

</style>
