<template>
    <a-cascader
        v-model="riskCategory"
        change-on-select
        :options="categoryList"
        :disabled="disabled"
        placeholder="请选择风险分类"
        @change="onChange"
        @blur="handleBlur" />
    <!--  :field-names="{ label: 'masterDataClassificationName', value: 'masterDataClassificationId', children: 'children' }" -->
</template>

<script>
import { Cascader as ACascader } from 'ant-design-vue'
import getCloudRiskCategoryService from '@service/gbCloud/getCloudRiskCategoryService'

import { getMap, getParentIds, recursionToTree } from '@utils/toTree'

export default {
    name: 'RiskCategorySelect',
    components: {
        ACascader
    },
    props: {
        option: {
            type: Object,
            default() {
                return {}
            }
        },
        value: {
            type: Array,
            default() {
                return []
            }
        },
        // 回显的id(修改使用)
        categoryId: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            categoryList: [],
            mapCategory: null,
            parentIds: []// 回显的ids
        }
    },
    computed: {
        riskCategory: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
                this.$emit('blur')
            }
        }
    },
    watch: {
        option: {
            handler(val) {
                if (val && val.source) {
                    this.getCategoryList()
                } else {
                    this.categoryList = []
                }
            },
            immediate: true
        }
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        handleOption(option) {
            this.$emit('option', option)
        },
        onChange(val) { // categoryId
            const len = val.length
            this.$emit('change', val[len - 1])
        },
        async getCategoryList() {
            try {
                this.$emit('loading', true)
                const { masterDataId, source } = this.option // 请求参数
                const { categoryId } = this
                const res = await getCloudRiskCategoryService({
                    masterDataId,
                    source
                })
                this.categoryList = recursionToTree(res, 'masterDataClassificationName', 'masterDataClassificationId')
                // 树形转map
                this.mapCategory = getMap(this.categoryList)
                if (categoryId) {
                    this.riskCategory = getParentIds(this.mapCategory, categoryId)
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
