<template>
    <a-descriptions slot="title" :title="info.name" :column="3">
        <a-descriptions-item label="商品ID">
            {{ info.id }}
        </a-descriptions-item>
        <a-descriptions-item label="创建人">
            {{ info.createName }}
        </a-descriptions-item>
        <a-descriptions-item label="更新时间">
            {{ info.createDateTime }}
        </a-descriptions-item>
    </a-descriptions>
</template>

<script>
import { Descriptions as ADescriptions } from 'ant-design-vue'

export default {
    name: 'CommodityHead',
    components: {
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang='scss'>

</style>
