<template>
    <a-form-model
        ref="ruleForm"
        :rules="rules"
        :model="form">
        <table>
            <tbody>
                <tr
                    class="master-field-attribute">
                    <td>
                        <a-form-model-item  style="width: 100px">
                            {{ index + 1 }}
                        </a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item  style="width: 100px">
                            {{ record.name }}
                        </a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item style="width: 150px">
                            <a-radio-group v-model="form.value">
                                <a-radio :value="isSwitch">
                                    是
                                </a-radio>
                                <a-radio :value="noSwitch">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                    </td>
                    <td>
                        <div  style="width: 200px">
                            <a-form-model-item
                                v-show="form.value===isSwitch"
                                prop="masterDataId">
                                <risk-obj-select
                                    v-model="form.masterDataId"
                                    @option="handleOption" />
                            </a-form-model-item>
                            <a-form-model-item v-show="form.value===noSwitch">/</a-form-model-item>
                        </div>
                    </td>
                    <td>
                        <div  style="width: 200px">
                            <a-form-model-item
                                v-show="form.value===isSwitch"
                                prop="formCategoryArr">
                                <risk-category-select
                                    v-show="form.value===isSwitch"
                                    v-model="form.formCategoryArr"
                                    ref="categoryId"
                                    :option="masterOption"
                                    @change="handleCategoryChange" />
                            </a-form-model-item>
                            <a-form-model-item v-show="form.value===noSwitch">/</a-form-model-item>
                        </div>
                    </td>
                    <td>
                        <div style="width: 300px">
                            <a-form-model-item
                                v-show="form.value===isSwitch"
                                prop="riskFactorDefinitionIds">
                                <select-option-label-prop
                                    v-model="form.riskFactorDefinitionIds"
                                    ref="selectOption"
                                    option-label="factorName"
                                    option-value="id"
                                    :select-options-data="factorDataList"
                                    placeholder="请选择风险因子"
                                    :filter-option="false"
                                />
                            </a-form-model-item>
                            <a-form-model-item v-show="form.value===noSwitch">/</a-form-model-item>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </a-form-model>
</template>

<script>
import { Radio as ARadio, FormModel as AFormModel } from 'ant-design-vue'
import RiskObjSelect from '@weights/GbCloud/RiskObjSelect'
import RiskCategorySelect from '@weights/GbCloud/RiskCategorySelect'
import SelectOptionLabelProp from '@weights/SelectOptionLabelProp'
import getCanAddRiskFactorService from '@service/ruleEngine/DataCheckSetting/getCanAddRiskFactorService'

const isSwitch = 1// 是
const noSwitch = 2// 否
export default {
    name: 'MasterFieldAttribute',
    components: {
        ARadio,
        ARadioGroup: ARadio.Group,
        RiskObjSelect,
        RiskCategorySelect,
        SelectOptionLabelProp,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    props: {
        index: {
            type: Number,
            default: 0
        },
        recordMasterAttribute: {
            type: Object,
            default() {
                return {}
            }
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        attributesData: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            isSwitch,
            noSwitch,
            value: isSwitch,
            masterOption: null,
            factorDataList: [],
            form: {
                masterDataId: '',
                formCategoryArr: [],
                categoryId: '',
                riskFactorDefinitionIds: [],
                dataVerificationModelId: '', // 获取因子列表也需要这个字段
                value: noSwitch,
                displayValue: ''
            },
            rules: {
                masterDataId: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ],
                formCategoryArr: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ],
                riskFactorDefinitionIds: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    computed: {
        queryId() {
            return this.$route.query.queryId
        },
        // 后台需要的参数(处理form)
        params() {
            // eslint-disable-next-line no-unused-vars
            const { formCategoryArr, ...params } = this.form
            return params
        }
    },
    watch: {
    // 改属性数据
        record: {
            handler(val) {
                this.form.displayValue = val.value
            },
            immediate: true
        },
        masterOption(val) {
            this.form.categoryId = ''
            this.form.source = val.source
            this.form.riskFactorDefinitionIds = []// 清空下级关联内容
            this.factorDataList = []
        },
        'form.categoryId': {
            handler() {
                this.getCanAddRiskFactor()
                this.form.riskFactorDefinitionIds = []// 清空下级关联内容
            }
        }
    },
    created() {
        this.form.dataVerificationModelId = this.queryId
    },
    methods: {
        handleOption(option) {
            this.masterOption = option
        },
        handleCategoryChange(val) {
            this.form.categoryId = val
        },
        resetFields() {
            this.$refs.ruleForm.resetFields()
            this.form.value = noSwitch
        },
        handleFormValidate() {
            let res = null
            if (this.form.value === isSwitch) {
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        const { params } = this
                        res = params
                    }
                })
            } else if (this.form.value === noSwitch) {
                res = ''
            }
            return res
        },
        async getCanAddRiskFactor() {
            try {
                const { params } = this
                const res = await getCanAddRiskFactorService(params)
                this.factorDataList = res.filter(item => item.isUsed === false)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang='scss'>
.master-field-attribute {
  td {
    padding: 0 10px;
    text-align: center;
    min-width: 80px;
  }
}
</style>
