<!-- 资信报告 -->
<template>
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        class="credit-report">
        <div class="credit-report__title" style="font-size: 15px">企业征信报告</div>
        <div v-if="delay(1)" class="credit-report__name">
            <a-button
                v-show="!isLook"
                type="primary"
                style="margin-left: 25px"
                @click="handleFillZero">
                一键填0
            </a-button>
            <a-button
                v-show="!isLook"
                type="primary"
                style="margin-left: 25px"
                @click="handleCredit">
                获取征信
            </a-button>
            <div v-if="delay(2)" style="display: flex; justify-content: center">
                <a-form-model-item
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 16 }"
                    label="企业名称"
                    prop="enterpriseName"
                    style="flex: 1">
                    {{ form.enterpriseName }}
                </a-form-model-item>
                <a-form-model-item
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 16 }"
                    label="报告日期"
                    prop="reportDate"
                    style="flex: 1">
                    <span v-if="isLook">{{ form.reportDate }}</span>
                    <a-date-picker
                        v-else
                        v-model="form.reportDate"
                        format="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        @change="handleTimeChange" />
                </a-form-model-item>
            </div>

            <div class="credit-report__content">
                <div class="credit-report__title">信息概要</div>
                <table v-if="delay(3)" class="credit-report__table">
                    <thead class="credit-report__thead">
                        <tr>
                            <th class="credit-report__thead__th credit-report__padding-left">首次有信贷交易的年份</th>
                            <th class="credit-report__thead__th credit-report__padding-left">发生信贷交易的机构数</th>
                            <th class="credit-report__thead__th credit-report__padding-left">当前有未结清信贷交易的机构数</th>
                            <th class="credit-report__thead__th credit-report__padding-left">首次有相关还款责任的年份</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="credit-report__bottom">
                            <td class="credit-report__padding-left">
                                <a-form-model-item prop="scyxdjydnf">
                                    <a-input
                                        v-model="form.scyxdjydnf"
                                        :disabled="isLook" />
                                </a-form-model-item>
                            </td>
                            <td class="credit-report__padding-left">
                                <a-form-model-item prop="xdjyjgs">
                                    <a-input
                                        v-model="form.xdjyjgs"
                                        :disabled="isLook" />
                                </a-form-model-item>
                            </td>
                            <td class="credit-report__padding-left">
                                <a-form-model-item prop="dqywjqxdjydjgs">
                                    <a-input
                                        v-model="form.dqywjqxdjydjgs"
                                        :disabled="isLook" />
                                </a-form-model-item>
                            </td>
                            <td class="credit-report__padding-left">
                                <a-form-model-item prop="scyxghkzrdnf">
                                    <a-input
                                        v-model="form.scyxghkzrdnf"
                                        :disabled="isLook" />
                                </a-form-model-item>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <a-row type="flex" class="credit-report__thead credit-report__margin-top">
                    <a-col :flex="1" class="credit-report__padding-left">借贷交易</a-col>
                    <a-col :flex="1" class="credit-report__padding-left">担保交易</a-col>
                </a-row>
                <a-row v-if="delay(4)" type="flex" class="credit-report__bottom">
                    <a-col
                        class="credit-report__bottom credit-report__padding-left"
                        :span="6">
                        余额
                    </a-col>
                    <a-col class="credit-report__bottom credit-report__padding-left" :span="6">
                        <!--            借贷交易 余额 -->
                        <a-form-model-item prop="xdjyyg">
                            <a-input
                                v-model="form.xdjyyg"
                                :disabled="isLook" />
                        </a-form-model-item>
                    </a-col>
                    <a-col class="credit-report__bottom credit-report__padding-left" :span="6">
                        <a-form-model-item>
                            余额
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6" class="credit-report__bottom credit-report__padding-left">
                        <!--            担保交易 余额 -->
                        <a-form-model-item prop="dbjyye">
                            <a-input
                                v-model="form.dbjyye"
                                :disabled="isLook" />
                        </a-form-model-item>
                    </a-col>

                    <a-col class="credit-report__bottom credit-report__padding-left" :span="6">
                        <a-form-model-item>
                            其中：被追偿余额
                        </a-form-model-item>
                    </a-col>
                    <a-col class="credit-report__bottom credit-report__padding-left" :span="6">
                        <!--            借贷交易被追偿余额 -->
                        <a-form-model-item prop="jdjybzcye">
                            <a-input
                                v-model="form.jdjybzcye"
                                :disabled="isLook" />
                        </a-form-model-item>
                    </a-col>
                    <a-col class="credit-report__bottom credit-report__padding-left" :span="6">
                        <a-form-model-item>
                            其中：关注类余额
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6" class="credit-report__bottom credit-report__padding-left">
                        <!--            担保交易关注类余额 -->
                        <a-form-model-item prop="dbjygzlye">
                            <a-input
                                v-model="form.dbjygzlye"
                                :disabled="isLook" />
                        </a-form-model-item>
                    </a-col>

                    <a-col class="credit-report__bottom credit-report__padding-left" :span="6">
                        <a-form-model-item>
                            关注类余额
                        </a-form-model-item>
                    </a-col>
                    <a-col class="credit-report__bottom credit-report__padding-left" :span="6">
                        <!--            借贷交易关注类余额 -->
                        <a-form-model-item prop="jdjygzlye">
                            <a-input
                                v-model="form.jdjygzlye"
                                :disabled="isLook" />
                        </a-form-model-item>
                    </a-col>
                    <a-col class="credit-report__bottom credit-report__padding-left" :span="6">
                        <a-form-model-item>不良类余额</a-form-model-item>
                    </a-col>
                    <a-col :span="6" class="credit-report__bottom credit-report__padding-left">
                        <a-form-model-item prop="dbjybllye">
                            <a-input
                                v-model="form.dbjybllye"
                                :disabled="isLook" />
                        </a-form-model-item>
                    </a-col>

                    <a-col :span="6" class="credit-report__padding-left">
                        <a-form-model-item>不良类余额</a-form-model-item>
                    </a-col>
                    <a-col :span="6" class="credit-report__padding-left">
                        <a-form-model-item prop="jdjybllye">
                            <a-input
                                v-model="form.jdjybllye"
                                :disabled="isLook" />
                        </a-form-model-item>
                    </a-col>
                </a-row>


                <a-row type="flex" class="credit-report__thead credit-report__margin-top ">
                    <a-col :flex="1" class="credit-report__padding-left">非信贷交易账户数</a-col>
                    <a-col :flex="1" class="credit-report__padding-left">欠税记录条数</a-col>
                    <a-col :flex="1" class="credit-report__padding-left">民事判决记录条数</a-col>
                    <a-col :flex="1" class="credit-report__padding-left">强制执行记录条数</a-col>
                    <a-col :flex="1" class="credit-report__padding-left">行政处罚记录条数</a-col>
                </a-row>
                <a-row  v-if="delay(5)" type="flex" class="credit-report__bottom">
                    <a-col
                        v-for="item in fiveArr"
                        :key="item"
                        class="credit-report__padding-left"
                        :flex="1">
                        <a-form-model-item :prop="item">
                            <a-input
                                v-model="form[item]"
                                :disabled="isLook" />
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <div v-if="delay(6)" class="credit-report__xinxi">
                    <div class="credit-report__thead credit-report__margin-top credit-report__title"
                         style="text-align: center;">
                        未结清信贷及授信信息概要
                    </div>

                    <a-row type="flex" class="credit-report__thead ">
                        <a-col flex="200px" />
                        <a-col :flex="1">正常类</a-col>
                        <a-col :flex="1" />
                        <a-col :flex="1">关注类</a-col>
                        <a-col :flex="1" />
                        <a-col :flex="1">不良类</a-col>
                        <a-col :flex="1" />
                        <a-col :flex="1">合计</a-col>
                        <a-col :flex="1" />
                    </a-row>
                    <a-row type="flex" class="credit-report__thead ">
                        <a-col flex="200px" />
                        <a-col :flex="1">账户数</a-col>
                        <a-col :flex="1">余额</a-col>
                        <a-col :flex="1">账户数</a-col>
                        <a-col :flex="1">余额</a-col>
                        <a-col :flex="1">账户数</a-col>
                        <a-col :flex="1">余额</a-col>
                        <a-col :flex="1">账户数</a-col>
                        <a-col :flex="1">余额</a-col>
                    </a-row>

                    <template v-if="delay(7)">
                        <a-row
                            v-for="item in sumArr"
                            :key="item.name"
                            type="flex">
                            <!-- type="flex" -->
                            <a-col flex="200px" class="credit-report__bottom credit-report__padding-left">
                                {{
                                    item.name
                                }}
                            </a-col>
                            <a-col
                                v-for="item2 in item.arr"
                                :key="item2"
                                :flex="1"
                                class=" credit-report__bottom">
                                <a-form-model-item
                                    :prop="item2">
                                    <a-input
                                        v-model="form[item2]"
                                        :disabled="isLook" />
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </template>
                </div>

                <a-row type="flex" class="credit-report__thead credit-report__thead credit-report__margin-top">
                    <a-col class="credit-report__padding-left" :flex="1">非循环信用额度</a-col>
                    <a-col class="credit-report__padding-left" :flex="1">循环信用额度</a-col>
                </a-row>
                <a-row class="credit-report__thead ">
                    <a-col class="credit-report__padding-left" :span="4">总额</a-col>
                    <a-col class="credit-report__padding-left" :span="4">已用额度</a-col>
                    <a-col class="credit-report__padding-left" :span="4">剩余可用额度</a-col>
                    <a-col class="credit-report__padding-left" :span="4">总额</a-col>
                    <a-col class="credit-report__padding-left" :span="4">已用额度</a-col>
                    <a-col class="credit-report__padding-left" :span="4">剩余可用额度</a-col>
                </a-row>
                <a-row v-if="delay(8)" class="credit-report__bottom">
                    <a-col
                        v-for="item in xuanhuanedu"
                        :key="item"
                        class="credit-report__padding-left"
                        :span="4"
                        lass="credit-report__thead__no-left-padding">
                        <a-form-model-item
                            :prop="item">
                            <a-input
                                v-model="form[item]"
                                :disabled="isLook" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </div>
        </div>
    </a-form-model>
</template>

<script>
import {
    Input as AInput,
    FormModel as AFormModel,
    Col as ACol,
    Row as ARow,
    Button as AButton,
    DatePicker as ADatePicker
} from 'ant-design-vue'
import moment from 'moment'
import getEnterpriseReportService from '@service/enterprise/getEnterpriseReportService'
import getCreditReportBySccService from '@service/enterprise/getCreditReportBySccService'
import delay from '@mixins/delay'

export default {
    name: 'CreditReport',
    components: {
        ADatePicker,
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ACol,
        ARow,
        AButton
    },
    mixins: [delay(8)],
    props: {
        isLook: {
            type: Boolean,
            default: true
        },
        info: {
            type: [Object, null],
            default() {
                return null
            }
        }
    },
    data() {
        return {
            sort: 0,
            fiveArr: [
                'fxdjyzhs',
                'qsjlts',
                'mspjjlts',
                'qzzxjlts',
                'xzcfjlts'
            ],
            sumArr: Object.freeze([
                {
                    name: '中长期借款',
                    arr: [
                        'zcqjkzclzhs',
                        'zcqjkzclye',
                        'zcqjkgzlzhs',
                        'zcqjkgzlye',
                        'zcqjkbllzhs',
                        'zcqjkbllye',
                        'zcqjkhjzhs',
                        'zcqjkhjye'
                    ]
                },
                {
                    name: '短期借款',
                    arr: [
                        'dqjkzclzhs',
                        'dqjkzclye',
                        'dqjkgzlzhs',
                        'dqjkgzlye',
                        'dqjkbllzhs',
                        'dqjkbllye',
                        'dqjkhjzhs',
                        'dqjkhjye'
                    ]
                },
                {
                    name: '银行承兑汇票',
                    arr: [
                        'yhcdhpzclzhs',
                        'yhcdhpzclye',
                        'yhcdhpgzlzhs',
                        'yhcdhpgzlye',
                        'yhcdhpbllzhs',
                        'yhcdhpbllye',
                        'yhcdhphjzhs',
                        'yhcdhphjye'
                    ]
                },
                {
                    name: '银行保函',
                    arr: [
                        'yhbhzclzhs',
                        'yhbhzclye',
                        'yhbhgzlzhs',
                        'yhbhgzlye',
                        'yhbhbllzhs',
                        'yhbhbllye',
                        'yhbhhjzhs',
                        'yhbhhjye'
                    ]
                },
                {
                    name: '其他担保交易',
                    arr: [
                        'qtdbjyzclzhs',
                        'qtdbjyzclye',
                        'qtdbgzlzhs',
                        'qtdbgzlye',
                        'qtdbjybllzhs',
                        'qtdbjybllye',
                        'qtdbjyhjzhs',
                        'qtdbjyhjye'
                    ]
                },
                {
                    name: '合计',
                    arr: [
                        'zclzhshj',
                        'zclyehj',
                        'gzlzhshj',
                        'gzlyehj',
                        'bllzhshj',
                        'bllyehj',
                        'hjzhs',
                        'hjye'
                    ]
                }
            ]),
            // 循环额度
            xuanhuanedu: [
                'fxhxyedze',
                'fxhxyedyyed',
                'fxhxyedsykyed',
                'xhxyedze',
                'xhxyedyyed',
                'xhxyedsykyed'
            ],
            form: {
                // enterpriseName: '',
                // reportDate: '',

                scyxdjydnf: '',
                xdjyjgs: '',
                dqywjqxdjydjgs: '',
                scyxghkzrdnf: '',

                xdjyyg: '',
                dbjyye: '',
                jdjybzcye: '',
                dbjygzlye: '',
                jdjygzlye: '',
                dbjybllye: '',
                jdjybllye: '',

                fxdjyzhs: '',
                qsjlts: '',
                mspjjlts: '',
                qzzxjlts: '',
                xzcfjlts: '',

                zcqjkzclzhs: '',
                zcqjkzclye: '',
                zcqjkgzlzhs: '',
                zcqjkgzlye: '',
                zcqjkbllzhs: '',
                zcqjkbllye: '',
                zcqjkhjzhs: '',
                zcqjkhjye: '',

                dqjkzclzhs: '',
                dqjkzclye: '',
                dqjkgzlzhs: '',
                dqjkgzlye: '',
                dqjkbllzhs: '',
                dqjkbllye: '',
                dqjkhjzhs: '',
                dqjkhjye: '',

                yhcdhpzclzhs: '',
                yhcdhpzclye: '',
                yhcdhpgzlzhs: '',
                yhcdhpgzlye: '',
                yhcdhpbllzhs: '',
                yhcdhpbllye: '',
                yhcdhphjzhs: '',
                yhcdhphjye: '',

                yhbhzclzhs: '',
                yhbhzclye: '',
                yhbhgzlzhs: '',
                yhbhgzlye: '',
                yhbhbllzhs: '',
                yhbhbllye: '',
                yhbhhjzhs: '',
                yhbhhjye: '',

                qtdbjyzclzhs: '',
                qtdbjyzclye: '',
                qtdbgzlzhs: '',
                qtdbgzlye: '',
                qtdbjybllzhs: '',
                qtdbjybllye: '',
                qtdbjyhjzhs: '',
                qtdbjyhjye: '',

                zclzhshj: '',
                zclyehj: '',
                gzlzhshj: '',
                gzlyehj: '',
                bllzhshj: '',
                bllyehj: '',
                hjzhs: '',
                hjye: '',

                fxhxyedze: '',
                fxhxyedyyed: '',
                fxhxyedsykyed: '',
                xhxyedze: '',
                xhxyedyyed: '',
                xhxyedsykyed: ''
            },
            rules: {
                enterpriseName: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入企业名称'
                    }
                ],
                reportDate: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择报告日期'
                    }
                ]
            }
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    created() {
        const { form, info } = this
        Object.keys(form).forEach(item => {
            this.rules[item] = [
                {
                    required: true,
                    message: '请输入',
                    trigger: 'blur'
                }
            ]
        })
        if (info) {
            this.form = info
        } else {
            this.getReport()
        }
    },
    methods: {
        async handleCredit() {
            try {
                const { riskControlNumber, form } = this
                const res = await getCreditReportBySccService({
                    riskControlNumber
                })
                if (res) {
                    this.form = res
                    this.form.enterpriseName = form.enterpriseName
                    this.$message.success('征信报告获取成功')
                } else {
                    this.$message.warn('当前投保人无可用征信报告')
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        disabledDate(current) {
            return current && current > moment().endOf('day')
        },
        handleTimeChange(moment, stringTime) {
            this.form.reportDate = stringTime
        },
        handleFillZero() {
            const { form } = this
            Object.keys(form).forEach(item => {
                if (item !== 'enterpriseName' && item !== 'reportDate' && item !== 'id'
                    && item !== 'createDateTime' && item !== 'modifyDateTime'
                ) {
                    if (!form[item]) {
                        form[item] = 0
                    }
                }
            })
        },
        submit() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    res = this.form
                }
            })
            return res
        },
        // 获取企业征信报告
        async getReport() {
            try {
                const { riskControlNumber } = this
                this.form = await getEnterpriseReportService({
                    riskControlNumber
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.credit-report {
    min-width: 1200px;
    overflow-x: auto;

    h2 {
        text-align: center;
    }


    &__name {
        margin: 20px 0;
    }

    &__table {
        width: 100%;

        tr {
            line-height: $custom-table-thead-height
        }
    }

    //th, td, .ant-col {
    //  padding-left: 25px;
    //}


    &__bottom {
        border-bottom: 1px solid #e8e8e8;
    }

    &__thead {
        background-color: $custom-table-title-background-color;
        line-height: 44px;
        border-bottom: 1px solid #e8e8e8;

        &__th {
            width: 25%;
        }

        &__no-left-padding {
            padding-left: 0 !important;

            //input {
            //  width: 130px;
            //}
        }
    }

    &__title {
        background-color: $custom-table-title-background-color;
        line-height: 44px;
        border-bottom: 1px solid #e8e8e8;
        text-align: center;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
    }

    &__margin-top {
        margin-top: 45px;
    }

    .ant-col {
        min-width: 110px;
        line-height: 44px;
    }

    input {
        max-width: 110px;
    }

    &__xinxi {
        .ant-col {
            width: 110px !important;
        }
    }

    &__padding-left {
        padding-left: 25px
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form-explain {
        position: absolute;
        margin-top: -30px;
        margin-left: 115px;
        min-width: 50px;
    }
}
</style>
