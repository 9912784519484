<!-- 用户中心-用户详情-用户信息 -->
<template>
    <div>
        <audit-state
            :info="info" />
        <container-panel class="basic-info-tab-panel">
            <!--    <template slot="content"> -->
            <descriptions-with-head title="基本信息">
                <a-button
                    v-show="canAdd"
                    slot="button"
                    v-auth="RULE_ENGINE_EDIT_GRADE_MODEL_INFO_BUTTON"
                    type="primary"
                    @click="handleEdit">
                    编辑
                </a-button>
                <a-descriptions bordered :column="1">
                    <a-descriptions-item label="评分模型名称">
                        {{ empty(info.modelExamplesName) }}
                    </a-descriptions-item>
                    <a-descriptions-item label="模型描述">
                        {{
                            empty(info.description)
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="业务类型">
                        {{
                            empty(BUSINESS_TYPE_MAP[info.riskBusinessType])
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="产品分类">
                        {{
                            empty(info.riskCategoryName)
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="评分卡分类">
                        {{
                            empty(SCORE_CARD_MAP[info.scoringModelType])
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="评分卡基础分">
                        {{
                            empty(info.basicScoring)
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="评分计算方式">
                        {{
                            empty(WEIGHT_MAP[info.isWeight])
                        }}
                    </a-descriptions-item>
                    <!--          <a-descriptions-item label="地区范围"> -->
                    <!--            {{ info.areaName }} -->
                    <!--          </a-descriptions-item> -->
                </a-descriptions>
            </descriptions-with-head>
            <!--    </template> -->


            <add-or-edit-grade-modal
                mode="edit"
                :visible="visible"
                :record="info"
                @cancel="handleCancel"
                @success="handleSuccess" />
        </container-panel>
    </div>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import { Descriptions as ADescriptions, Button as AButton } from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import AuditState from '@weights/AuditState'

import { RULE_ENGINE_EDIT_GRADE_MODEL_INFO_BUTTON } from '@constant/authEnum/ruleEngine'
import { BUSINESS_TYPE_MAP, SCORE_CARD_MAP, WEIGHT_MAP } from '@constant/enum'

import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'
import showModelButton from '@utils/showModelButton'
export default {
    name: 'BasicInfoTabPanel',
    components: {
        ContainerPanel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        DescriptionsWithHead,
        AButton,
        AddOrEditGradeModal: () => import('@views/RuleEngine/GradeModel/GradeModelList/AddOrEditGradeModal'),
        AuditState
    },
    mixins: [empty, enumOperate],
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            BUSINESS_TYPE_MAP,
            RULE_ENGINE_EDIT_GRADE_MODEL_INFO_BUTTON,
            SCORE_CARD_MAP,
            WEIGHT_MAP
        }
    },
    computed: {
    // 可添加
        canAdd() {
            const { isLook, info } = this
            return !isLook && showModelButton.canAdd(info.auditState)
        }
    },
    methods: {
        handleSuccess() {
            this.visible = false
            this.$emit('success')
        },
        handleCancel() {
            this.visible = false
        },
        handleEdit() {
            this.visible = true
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.basic-info-tab-panel {
  .ant-descriptions-item-label {
    width: $descriptions-item-label-width;
    padding: $descriptions-item-label-padding;
  }
}
</style>
