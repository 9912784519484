<template>
    <div>
        <a-select v-model="dictValue" placeholder="请选择" @blur="handleBlur">
            <a-select-option
                v-for="item in dataList"
                :key="item.name"
                :value="item.value">
                {{ item.name }}
            </a-select-option>
        </a-select>
    </div>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'
import getAttributesService from '@service/gbCloud/getAttributesService'

export default {
    name: 'DictSelect',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: {
            type: [String, Number]
        },
        params: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {
        dictValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    created() {
        this.getAttributes()
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        // 获取属性值
        async getAttributes() {
            const { dataType, dictionaryCategoryId, source } = this.params
            try {
                this.loading = true
                this.dataList = await getAttributesService({
                    dataType,
                    dictionaryCategoryId,
                    source
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
