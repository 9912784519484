<template>
    <a-cascader
        v-model="areaModel"
        :key="key"
        :options="dataList"
        :disabled="disabled"
        :show-search="{ filter }"
        change-on-select
        placeholder="请选择地区"
        @blur="handleBlur"
        @change="handleChange"
    />
<!--    :field-names="{ label: 'name', value: 'id', children: 'subarea' }" -->
</template>

<script>

import { Cascader as ACascader } from 'ant-design-vue'
import getAreaTreeService from '@service/getAreaTreeService'
import { COUNTRY_CODE } from '@constant/Const'
import { getMapAndTree } from '@utils/toTree'
export default {
    name: 'AreaCom',
    components: {
        ACascader
    },
    props: {
        value: {
            type: [String, null]
        },
        // 是否显示'国家'
        showCounty: {
            type: Boolean,
            default: false
        },
        // 是否过滤区
        isFilter: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: [],
            dataList: [],
            defaultValue: [],
            key: '',
            mapArea: null
        }
    },
    computed: {
        areaModel: {
            get() {
                const arr = []
                this.getParents(this.value, arr)
                return arr
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        filter(inputValue, path) {
            return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
        },
        async getList() {
            try {
                const { showCounty, isFilter } = this
                let data = await getAreaTreeService()
                if (isFilter) {
                    data = this.filterArea(data)
                }

                if (!showCounty) {
                    data = data.filter(item => item.value !== COUNTRY_CODE)
                }

                // 树形转map
                const { tree, map } = getMapAndTree(data)
                this.mapArea = map
                this.dataList = tree

                // this.getParents(this.value, this.defaultValue,)
                this.key = JSON.stringify(this.defaultValue)
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleChange(val) {
            const len = val.length
            const res = len ? val[len - 1] : ''
            this.areaModel = res
        },
        handleBlur() {
            this.$emit('blur')
        },
        // 回显 获取初始值
        async getParents(code, parentIds = []) {
            if (!code) return
            if (!this.mapArea) {
                await this.getList()
            }
            let item = this.mapArea.get(code)
            // 有的是中文回显
            if (!item) return
            while (item && item.parent) {
                parentIds.unshift(item.value)
                item = item.parent
            }
            parentIds.unshift(item.value)
        },
        getChildIds(value, ChildIds) {
            const { mapArea } = this
            const item = mapArea.get(value)
            if (item && item.children) {
                item.children.forEach(item2 => {
                    ChildIds.push(item2.value)
                    this.getChildIds(item2.value, ChildIds)
                })
            }
        },
        /**
         * 根据value找到name
         * */
        getName(value, name = []) {
            const { mapArea } = this
            const item = mapArea.get(value)
            name.unshift(item.label)
            if (item.parent) {
                this.getName(item.parent.value, name)
            }
            return name
        },
        /**
         * 取省市数据 过滤区
         * */
        filterArea(arr) {
            let res = []
            arr.forEach(item => {
                if (item.children) {
                    item.children = item.children.map(item2 => {
                        let target = JSON.parse(JSON.stringify(item2))
                        delete target.children
                        return target
                    })
                }
                res.push(item)
            })
            return res
        }
    }
}
</script>

<style lang='scss'>

</style>
