<template>
    <a-select
        v-model="minValueComparator"
        :disabled="disabled"
        :style="{width}"
        placeholder="请选择"
        @blur="onBlur">
        <a-select-option
            v-for="item in GREATER_THAN_TYPE_ENUM_LIST"
            :key="item.value"
            :value="item.value">
            {{ item.label }}
        </a-select-option>
    </a-select>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'
import { GREATER_THAN_TYPE_ENUM_LIST } from '@constant/enum'

export default {
    name: 'MinComparator',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number
            // default:1
        },
        width: {
            type: String,
            default: '100px'
        }
    },
    data() {
        return {
            GREATER_THAN_TYPE_ENUM_LIST
        }
    },
    computed: {
        minValueComparator: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        onBlur() {
            this.$emit('blur')
        }
    }
}
</script>

<style lang='scss'>

</style>
