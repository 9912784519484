<template>
    <a-cascader
        v-model="model"
        :options="dataList"
        placeholder="请选择"
        change-on-select
        @change="onChange"
    />
</template>

<script>
import { Cascader as ACascader } from 'ant-design-vue'
import getAttributesService from '@service/gbCloud/getAttributesService'

import { getMap, getParentIds, recursionToTree } from '@utils/toTree'

export default {
    name: 'DictCascader',
    components: {
        ACascader
    },
    props: {
    // 回显值
        value: {
            type: String,
            default: ''
        },
        // 级联数据
        dtoList: {
            type: Array,
            default() {
                return []
            }
        },
        paramItem: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            defaultValue: [],
            map: null,
            dataList: []
        }
    },
    computed: {
        model: {
            get() {
                const { value, map } = this
                return value && map ? getParentIds(this.map, value) : []
            },
            set(value) {
                this.$emit('input', value)
                this.$emit('blur')
            }
        }
    },
    created() {
        const { dtoList } = this
        this.dataList = recursionToTree(dtoList, 'name', 'value')
        this.map = getMap(dtoList)
    },
    methods: {
        onChange(val) {
            const len = val.length
            const res = len ? val[len - 1] : ''
            this.model = res
        },
        // 获取属性值
        async getAttributes(params) {
            try {
                this.dataList = await getAttributesService(params)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
