<template>
    <!-- 施工资质 -->
    <a-cascader
        v-model="model"
        :options="dataList"
        placeholder="请选择"
        change-on-select
        @change="handleChange"
    />
</template>

<script>
import { Cascader as ACascader } from 'ant-design-vue'
import getListDictionaryService from '@service/gbCloud/getListDictionaryService'

import { getMap, getParentIds, recursionToTree } from '@utils/toTree'

export default {
    name: 'ConstructionQualification',
    components: {
        ACascader
    },
    props: {
        // 回显值
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dataList: [],
            mapData: null
        }
    },
    computed: {
        model: {
            get() {
                const { mapData, value } = this
                if (mapData && value) {
                    return getParentIds(this.mapData, this.value)
                }
                return []
            },
            set(value) {
                this.$emit('input', value)
                this.$emit('blur')
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleChange(val) {
            const len = val.length
            this.model = len ? val[len - 1] : ''
        },
        // 获取属性值
        async getList() {
            try {
                const result = await getListDictionaryService({
                    dictionaryCategoryId: '5bc8c48a-2bd6-4100-8169-18c88edf3b70',
                    dataType: 'DICT_CASCADE',
                    resultType: 2,
                    source: 1
                })
                this.dataList = recursionToTree(result, 'name', 'value')
                // 树形转map
                this.mapData = getMap(this.dataList)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
