<template>
    <container-panel class="audit-state">
        <div style="display: flex">
            <div>
                <a-icon type="info-circle" :style="{ fontSize: '24px' ,color:'#1890FF'}" />
            </div>
            <div style="margin-left: 10px;">
                <div class="audit-state__state-text">
                    审核状态：{{  AUDIT_STATE_MAP[info.auditState] || '/' }}
                </div>
                <div
                    v-if="info.auditOpinion"
                    class="audit-state__desc">
                    {{ info.auditOpinion }}
                </div>
            </div>
        </div>
    </container-panel>
</template>

<script>
import { Icon as AIcon } from 'ant-design-vue'
import ContainerPanel from '@components/ContainerPanel'
import { AUDIT_STATE_MAP } from '@constant/enum'

import enumOperate from '@mixins/enumOperate'
export default {
    name: 'AuditState',
    components: {
        ContainerPanel,
        AIcon
    },
    mixins: [enumOperate],
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            AUDIT_STATE_MAP
        }
    }
}
</script>

<style lang='scss'>
.audit-state {
  background: #fafdff;
  border-radius: 4px;
  border: 1px solid #1890ff;
  height: auto;
  word-break: break-all;
  &__state-text {
    color: #1c1c1c;
    margin-bottom: 6px;
    font-size: 15px;
  }
  &__desc {
    color: #333;
    font-size: 14px;
    height: auto;
  }
}
</style>
