import {
    // COMMODITY_CONFIG_RISK_CONTROL_AI_MODEL_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_BLACK_LIST_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_TYPE,
    // COMMODITY_CONFIG_RISK_CONTROL_ENTERPRISE_CREDIT_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_GRADE_MODEL_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_INSURE_COMPANY_TYPE,
    // COMMODITY_CONFIG_RISK_CONTROL_ORDER_SUBMIT_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_PERSON_CHECK_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_RISK_REMIND_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_SMART_ASSESS_TYPE,

    // COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_LABEL as TONG_YONG_HE_BAO,
    COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_LABEL as ZI_YOU_KUAI_SU_HE_BAO,
    COMMODITY_CONFIG_RISK_CONTROL_INSURE_COMPANY_LABEL as ZI_YOU_ZI_HE, COMMODITY_CONFIG_PROJECT_SCORE_TYPE
} from '@constant/enum'
/**
 * 解析数组
 * */
const joinArr = function (arr) {
    return arr.map(item => item.objectName).join(',')
}
const getSameTypeInfo = function (record) {
    const { productControlModel, modelName } = record
    return `${modelName}:${productControlModel.modelName}`
}
export const infoMap = {
    /* [COMMODITY_CONFIG_RISK_CONTROL_ORDER_SUBMIT_TYPE]:{
        showDetail(record){

        }
    },*/

    // 黑名单 1
    [COMMODITY_CONFIG_RISK_CONTROL_BLACK_LIST_TYPE]: {
        showDetail(record) {
            const { blackObjectVOS, modelName } = record
            const bRes = joinArr(blackObjectVOS)
            return `${modelName}:${bRes}`
        }
    },

    // 数据核验
    [COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_TYPE]: {
        showDetail: getSameTypeInfo,
        commodityInfo: 'DataCheckInfo',
        commodityApproveInfo: 'ApproveDataCheckInfo'
    },
    // 评分模型
    [COMMODITY_CONFIG_RISK_CONTROL_GRADE_MODEL_TYPE]: {
        showDetail: getSameTypeInfo,
        commodityInfo: 'GradeModelInfo',
        commodityApproveInfo: 'ApproveGradeModelInfo'
    },
    // [COMMODITY_CONFIG_RISK_CONTROL_ENTERPRISE_CREDIT_TYPE]:{
    //     showDetail(record){
    //
    //     }
    // },

    // 是否风险提示 3
    [COMMODITY_CONFIG_RISK_CONTROL_RISK_REMIND_TYPE]: {
        showDetail(record) {
            const { riskWoringObjectVOS, modelName } = record
            const res = joinArr(riskWoringObjectVOS)
            return `${modelName}:${res}`
        }
    },
    /* [COMMODITY_CONFIG_RISK_CONTROL_AI_MODEL_TYPE]:{
        showDetail(record){

        }
    },*/

    // 是否智能评估项 7
    [COMMODITY_CONFIG_RISK_CONTROL_SMART_ASSESS_TYPE]: {
        showDetail(record) {
            const { modelName } = record.productControlModel
            return `智能评估模型:${modelName}`
        },
        commodityInfo: 'SmartAssessInfo',
        commodityApproveInfo: 'ApproveSmartAssessInfo'
    },

    // 是否人工审核项 8
    [COMMODITY_CONFIG_RISK_CONTROL_PERSON_CHECK_TYPE]: {
        showDetail(record) {
            const { riskControlReviewAudit, riskControlSeniorAudit, productControlModel } = record
            const str1 = riskControlReviewAudit ? '风控复审' : ''
            const str2 = riskControlSeniorAudit ? '风控高审' : ''
            return `风险评估模型：${productControlModel.modelName}\n审核节点：风控初审，${str1}，${str2}`
        },
        commodityInfo: 'RiskAssessmentInfo',
        commodityApproveInfo: 'ApproveRiskAssessmentInfo'
    },
    // 保司规则A2
    [COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_TYPE]: {
        showDetail(record) {
            const ruleSetList = record.ruleSetList || []
            return `限制名单：无\n${ZI_YOU_KUAI_SU_HE_BAO}:${ruleSetList.map(item => item.modelExamplesName).join(',')}`
        }
    },
    // 自动核保规则A1
    [COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_TYPE]: {
        showDetail: getSameTypeInfo,
        commodityInfo: 'RuleSetInfo',
        commodityApproveInfo: 'ApproveRuleSetInfo'
    },
    // 保司自有自核规则 A2
    [COMMODITY_CONFIG_RISK_CONTROL_INSURE_COMPANY_TYPE]: {
        showDetail(record) {
            const ruleSetList = record.ruleSetList || []
            return `${ZI_YOU_ZI_HE}:${ruleSetList.map(item => item.modelExamplesName).join(',')}`
        }
    },
    [COMMODITY_CONFIG_PROJECT_SCORE_TYPE]: {
        showDetail({ productControlModel }) {
            const modelName = productControlModel.modelName || ''
            const gsysDictList = productControlModel.gsysDictList || []
            const nameList = gsysDictList.map(item => item.dataValue)
            let res = `特征工程评分卡模型:${modelName}\n`
            if (nameList.length) {
                res += `选择保司：${nameList}`
            }
            return res
        },
        commodityInfo: 'GradeModelInfo',
        commodityApproveInfo: 'ApproveGradeModelInfo'
    }
}

export const columns = [
    {
        title: '序号',
        dataIndex: 'id',
        align: 'left',
        scopedSlots: {
            customRender: 'id'
        },
        key: 'id',
        width: 200
    },
    {
        title: '风险模型',
        dataIndex: 'modelName',
        align: 'left',
        key: 'modelName',
        width: 300
    },
    {
        title: '是否选择',
        dataIndex: 'isChoose',
        align: 'left',
        scopedSlots: {
            customRender: 'isChoose'
        },
        key: 'isChoose',
        width: 150
    },
    {
        title: '风险模型配置项',
        dataIndex: 'configuration',
        align: 'left',
        scopedSlots: {
            customRender: 'configuration'
        },
        key: 'configuration'
    },
    {
        title: '操作',
        dataIndex: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        key: 'action',
        width: 200
    }
]
