<template>
    <a-select
        v-model="riskObj"
        :disabled="disabled"
        placeholder="请选择风险对象"
        @blur="handleBlur">
        <template v-for="item in masterList">
            <a-select-option
                :key="item.masterDataId"
                :value="item.masterDataId"
                @click="handleOption(item)">
                {{ item.masterDataName }}
            </a-select-option>
        </template>
    </a-select>
</template>

<script>
import { Select as ASelect } from 'ant-design-vue'

import getCloudMasterListService from '@service/gbCloud/getListMasterDataService'

export default {
    name: 'RiskObjSelect',
    components: {
        ASelect,
        ASelectOption: ASelect.Option
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            masterList: null
        }
    },
    computed: {
        riskObj: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    watch: {},
    created() {
        this.getMasterList()
    },
    methods: {
        handleOption(option) {
            this.$emit('option', option)
        },
        handleBlur() {
            this.$emit('blur')
        },
        async getMasterList() {
            try {
                this.masterList = await getCloudMasterListService()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
