<!-- 资料影像 -->
<template>
    <div class="material-images-panel__img-list">
        <a-empty v-if="!showList" style="margin: 20px auto" />
        <template v-else>
            <div
                v-for="(item,index) in dataList"
                :key="index">
                <image-card
                    :item="item"
                    class="material-images-panel__img-item"
                    @img="handleImg"
                    @pdf="handlePdf" />
            </div>
        </template>
        <!--    图片 -->
        <a-modal :visible="visible" :footer="null" @cancel="visible=false">
            <img alt="example" style="width: 100%" :src="currentImg">
        </a-modal>
        <!--    pdf -->
        <iframe-modal
            :url="currentPdf"
            :visible="pdfVisible"
            @cancel="pdfVisible=false" />
    </div>
</template>

<script>
import { Empty as AEmpty } from 'ant-design-vue'
import ImageCard from '@components/ImageCard'

export default {
    name: 'FileMaterial',
    components: {
        AEmpty,
        ImageCard,
        IframeModal: () => import('@weights/IframeModal')
    },
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            currentImg: '',
            visible: false,
            pdfVisible: false,
            currentPdf: ''
        }
    },
    computed: {
        showList() {
            return this.dataList.length
        }
    },
    mounted() {
    },
    methods: {
        handleImg(item) {
            this.visible = true
            this.currentImg = item.filePath
        },
        handlePdf(item) {
            this.pdfVisible = true
            this.currentPdf = item.filePath
        }
    }
}
</script>
<style lang="scss">
.material-images-panel {
  .ant-card-head {
    background: #fff;
  }

  &__img-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__img-item {
    margin-right: 50px;
    // flex: 1;
  }
}
</style>
