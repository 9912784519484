<!--
  预览PDF文件
-->
<template>
    <custom-modal
        class="iframe-modal-custom"
        title="文件预览"
        :visible="visible"
        :mask-closable="false"
        :width="1100"
        @close="handleCancel">
        <template slot="customFooter">
            <div />
        </template>
        <a-spin
            tip="加载中..."
            :spinning="!url">
            <iframe
                :src="url"
                frameborder="0"
                style="width: 1000px; height: 600px"
                class="iframe-modal-custom__iframe" />
        </a-spin>
    </custom-modal>
</template>

<script>
import { Spin as ASpin } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'

export default {
    name: 'IframeModal',
    components: {
        ASpin,
        CustomModal
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        }
    }
}
</script>

<style lang="scss">
.iframe-modal-custom {
    .ant-modal-body {
        display: flex;
        justify-content: center;
    }
}

</style>
