export default {
    t(oDiv, paramsObj, oEv, props) {
        // const {oldWidth, oldHeight, oldX, oldY, oldLeft, oldTop} = paramsObj
        const { oldHeight, oldY, oldTop } = paramsObj
        const { minHeight } = props
        const changeY = oldY - oEv.clientY // 改变的y值
        // 上下移动
        if (oEv.clientY <= oldTop || oDiv.offsetHeight > minHeight) {
            oDiv.style.top = oldTop - changeY + 'px'
            // oDiv.style.transform = `translateY(${oldTop - changeY}px)`
            oDiv.style.height = oldHeight + changeY + 'px'
        }
        // top边界判断
        if (oDiv.offsetTop <= 0) {
            oDiv.style.top = 0
            oDiv.style.height = oldHeight + oldY + 'px'
        }
    },
    b(oDiv, paramsObj, oEv) {
        const { oldHeight, oldY } = paramsObj
        oDiv.style.height = oldHeight + (oEv.clientY - oldY) + 'px'
        if (oEv.clientY >= document.documentElement.clientHeight) {
            oDiv.style.height = oldHeight + document.documentElement.clientHeight - oldY + 'px'
        }
    },
    l(oDiv, paramsObj, oEv, props) {
        const { oldWidth, oldX, oldLeft } = paramsObj
        const { minWidth } = props

        // 边界判断
        if (oDiv.offsetLeft <= 0) {
            oDiv.style.left = 0
        }
        const changeX = oldX - oEv.clientX
        // 左右移动
        if (oEv.clientX <= oldLeft || oDiv.offsetWidth > minWidth) {
            oDiv.style.width = oldWidth + changeX + 'px'
            oDiv.style.left = oldLeft - changeX + 'px'
        }
    },
    r(oDiv, paramsObj, oEv) {
        const { oldWidth, oldX, oldLeft } = paramsObj
        oDiv.style.width = oldWidth + (oEv.clientX - oldX) + 'px'
        oDiv.style.right = oldLeft - (oEv.clientX - oldX) + 'px'
        // 边界判断
        if (oDiv.offsetRight <= 0) {
            oDiv.style.right = 0
        }
    },
    tl(...arg) {
        this.t(...arg)
        this.l(...arg)
    },
    bl(...arg) {
        this.b(...arg)
        this.l(...arg)
    },
    tr(...arg) {
        this.t(...arg)
        this.r(...arg)
    },
    br(...arg) {
        this.b(...arg)
        this.r(...arg)
    }

}
