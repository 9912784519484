<template>
    <a-spin
        :spinning="loading">
        <a-select
            v-model="businessObjectArr"
            mode="multiple"
            placeholder="请选择业务对象"
            option-label-prop="label"
            :filter-option="false"
            @search="handleSearch"
            @blur="handleBlur"
            @change="handleChange">
            <a-select-option
                v-for="item in dataList"
                :key="item.objectCode"
                show-search
                :value="item.objectCode"
                :label="item.objectName">
                {{ item.objectName }}
            </a-select-option>
        </a-select>
    </a-spin>
</template>

<script>
import {
    Select as ASelect, Spin as ASpin
} from 'ant-design-vue'
import getBusinessObjectListService from '@service/gbCloud/getBusinessObjectListService'
export default {
    name: 'BusinessObjectSelect',
    components: {
        ASelect,
        ASelectOption: ASelect.Option,
        ASpin
    },
    props: {
        value: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            dataList: [],
            targetDataList: [],
            loading: false
        }
    },
    computed: {
        businessObjectArr: {
            get() {
                return this.value || undefined
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        mapData() {
            const { dataList } = this
            let map = new Map()
            dataList.forEach(item => {
                map.set(item.objectCode, item)
            })
            return map
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleSearch(val) {
            const { targetDataList } = this
            this.dataList = [...targetDataList.filter(item => item.objectName.includes(val))]
        },
        handleChange(values) {
            const { mapData } = this
            // res 为后端接收的数据
            const res = values.map(item => {
                if (mapData.has(item)) {
                    const recordMap = mapData.get(item)
                    const { objectCode, objectName, type } = recordMap
                    return {
                        objectCode,
                        objectName,
                        type
                    }
                }
            })
            this.$emit('change', res)
        },
        handleBlur() {
            this.$emit('blur')
        },
        async getList() {
            try {
                this.loading = true
                this.dataList = await getBusinessObjectListService()
                this.targetDataList = this.dataList
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
