<template>
    <container-panel>
        <descriptions-with-head title="风控模型信息">
            <a-table
                :columns="columns"
                :data-source="info.riskControllerModelDTOS"
                bordered
                row-key="id">
                <template slot="isChoose" slot-scope="text,action">
                    <template v-if="action.isChoose">是</template>
                    <template v-else>否</template>
                </template>
                <template slot="id" slot-scope="text,action,index">
                    {{ +index + 1 }}
                </template>
                <template slot="configuration" slot-scope="text,action">
                    <span style="white-space: pre-wrap">{{ parseContentToShow(action) }}</span>
                </template>
                <!-- 操作 -->
                <template slot="action" slot-scope="text,action">
                    <template v-if="showNotLook(action)">/</template>
                    <a-button
                        v-else
                        v-auth="COMMODITY_MANAGE_RISK_CONTROL_LOOK_BUTTON"
                        type="link"
                        @click="handleLook(action)">
                        查看
                    </a-button>
                </template>
            </a-table>
        </descriptions-with-head>
    </container-panel>
</template>

<script>
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import ContainerPanel from '@components/ContainerPanel'
import { Table as ATable, Button as AButton } from 'ant-design-vue'

import { COMMODITY_MANAGE_RISK_CONTROL_LOOK_BUTTON } from '@constant/authEnum/commodity'
import {
    GREATER_AND_LESS_THAN_TYPE_ENUM_LIST,
    COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_GRADE_MODEL_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_SMART_ASSESS_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_PERSON_CHECK_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_TYPE,
    COMMODITY_CONFIG_PROJECT_SCORE_TYPE
} from '@constant/enum'

/*  COMMODITY_CONFIG_RISK_CONTROL_BLACK_LIST_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_RISK_REMIND_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_ENTERPRISE_CREDIT_TYPE,// 1期不启用
    COMMODITY_CONFIG_RISK_CONTROL_AI_MODEL_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_INSURE_RULE_TYPE,
    COMMODITY_CONFIG_RISK_CONTROL_INSURE_COMPANY_TYPE
    COMMODITY_CONFIG_RISK_CONTROL_ORDER_SUBMIT_TYPE,*/
import enumOperate from '@mixins/enumOperate'
import { infoMap, columns } from './config'


export default {
    name: 'RiskControlInfo',
    components: {
        ATable,
        ContainerPanel,
        DescriptionsWithHead,
        AButton
    },
    mixins: [enumOperate],
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            columns,
            COMMODITY_MANAGE_RISK_CONTROL_LOOK_BUTTON,
            GREATER_AND_LESS_THAN_TYPE_ENUM_LIST,
            // 有查看按钮的button
            canLookButtonList: [
                COMMODITY_CONFIG_RISK_CONTROL_DATA_CHECK_MODEL_TYPE,
                COMMODITY_CONFIG_RISK_CONTROL_GRADE_MODEL_TYPE,
                COMMODITY_CONFIG_RISK_CONTROL_PERSON_CHECK_TYPE,
                COMMODITY_CONFIG_RISK_CONTROL_SMART_ASSESS_TYPE,
                COMMODITY_CONFIG_RISK_CONTROL_AUTO_UNDER_TYPE,
                COMMODITY_CONFIG_PROJECT_SCORE_TYPE
            ]
        }
    },
    computed: {
        currentRouteName() {
            const { isCommodityInfo, isApproveCommodityInfo } = this
            let routeName = ''
            if (isCommodityInfo) {
                routeName = 'commodityInfo'
            } else if (isApproveCommodityInfo) {
                routeName = 'commodityApproveInfo'
            }
            return routeName
        },
        // 商品详情
        isCommodityInfo() {
            return this.$route.name === 'CommodityInfo'
        },
        // 商品审核详情
        isApproveCommodityInfo() {
            return this.$route.name === 'CommodityApproveInfo'
        }
    },
    methods: {
        showNotLook(action) {
            const { isChoose, modelType } = action
            const res = this.canLookButtonList.find(item => +item === +modelType)
            if (!isChoose || !res) return '/'
        },
        handleLook(action) {
            const { currentRouteName } = this
            const { modelType } = action
            const { modelId } = action.productControlModel
            this.$router.push({
                name: infoMap[modelType][currentRouteName],
                query: {
                    id: modelId,
                    isLook: 1
                }
            })
        },
        parseContentToShow(record) {
            const { isChoose, modelType } = record
            const defaultValue = '/'
            if (!isChoose) return defaultValue
            let res = defaultValue
            try {
                res = infoMap[modelType]?.showDetail(record) || defaultValue
            } catch (e) {
                console.log('-> e', e)
            }
            return res
        }
    }
}
</script>

<style lang='scss'>

</style>
