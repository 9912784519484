<template>
    <div
        v-show="visible"
        class="drag-resize-box">
        <span class="r" />
        <span class="l" />
        <span class="t" />
        <span class="b" />
        <span class="br" />
        <span class="bl" />
        <span class="tr" />
        <span class="tl" />
        <div style="width: 100%; height: 100%; overflow: scroll">
            <slot />
        </div>
    </div>
</template>

<script>
import resizeFnObj from './resizeFnObj'

import { throttle } from '@utils/frequencyFn'

export default {
    name: 'DragResize',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        initWidth: {
            type: Number,
            default: 800
        },
        initHeight: {
            type: Number,
            default: 800
        },
        minWidth: {
            type: Number,
            default: 200
        },
        minHeight: {
            type: Number,
            default: 200
        },
        // 节流时间
        awaitTime: {
            type: Number,
            default: 50
        }
    },
    data() {
        return {}
    },
    mounted() {
        const { initWidth, initHeight, minWidth, minHeight } = this
        const oDiv = document.querySelector('.drag-resize-box')

        const width = initWidth < minWidth ? minWidth : initWidth
        const height = initHeight < minHeight ? minHeight : initHeight
        oDiv.style.width = width + 'px'
        oDiv.style.height = height + 'px'
        const aSpan = oDiv.getElementsByTagName('span')
        for (let i = 0; i < aSpan.length; i++) {
            this.resizeFn.call(this, oDiv, aSpan[i])
        }


        // 加入拖拽事件
        this.dragFn.call(this, oDiv)

        // 关闭事件
        oDiv.ondblclick = function () {
            this.$emit('close')
        }.bind(this)
    },
    methods: {
        throttle,
        /**
         * 收缩方法
         * @param oDiv   移动的dom
         * @param resizeDom 拉伸的dom
         * */
        resizeFn(oDiv, resizeDom) {
            const { minWidth, minHeight, $props } = this
            // Long Task
            resizeDom.onmousedown = function (ev) {
                const oEv = ev
                //   阻止事件的冒泡
                oEv.stopPropagation()
                const oldWidth = oDiv.offsetWidth // div的宽度
                const oldHeight = oDiv.offsetHeight // div 的高度
                const oldX = oEv.clientX // 点击时的x
                const oldY = oEv.clientY // 点击时的y
                const oldLeft = oDiv.offsetLeft // 点击时的offsetLeft
                const oldTop = oDiv.offsetTop// 点击时的offsetTop
                const paramsObj = {
                    oldWidth,
                    oldHeight,
                    oldX,
                    oldY,
                    oldLeft,
                    oldTop
                }

                function resizeMoveFn(ev) {
                    const oEv = ev
                    // 每个拖拽部位事件
                    resizeFnObj[resizeDom.className](oDiv, paramsObj, oEv, $props)

                    const width = parseInt(oDiv.offsetWidth, 10)
                    const height = parseInt(oDiv.style.height, 10)
                    // 最小宽高校验 重绘
                    if (width <= minWidth) {
                        oDiv.style.width = minWidth + 'px'
                    }
                    if (height <= minHeight) {
                        oDiv.style.height = minHeight + 'px'
                    }
                }

                // console.log(this)
                // document.onmousemove = this.throttle(resizeMoveFn, awaitTime).bind(this)
                document.onmousemove = function (ev) {
                    window.requestAnimationFrame(function () {
                        resizeMoveFn(ev)
                    })
                }


                document.onmouseup = function () {
                    document.onmousemove = null
                }
                return false
            }.bind(this)
        },
        /**
         * 拖拽方法
         * */
        dragFn(oDiv) {
            oDiv.onmousedown = function (evt) { // 鼠标按下事件
                const oEvent = evt
                //   阻止事件的冒泡
                oEvent.stopPropagation()
                const distanceX = oEvent.clientX - oDiv.offsetLeft
                const distanceY = oEvent.clientY - oDiv.offsetTop
                const docClientWidth = document.documentElement.clientWidth
                const docClientHeight = document.documentElement.clientHeight

                function moveFn(evt) {
                    const oEvent = evt
                    let left = oEvent.clientX - distanceX
                    let top = oEvent.clientY - distanceY
                    if (left <= 0) { // 防止越过浏览器左边缘
                        left = 0
                    } else if (left >= docClientWidth - oDiv.offsetWidth) { // 防止越过右边缘
                        left = docClientWidth - oDiv.offsetWidth
                    }
                    if (top <= 0) {
                        top = 0
                    } else if (top >= docClientHeight - oDiv.offsetHeight) {
                        top = docClientHeight - oDiv.offsetHeight
                    }
                    // oDiv.style.transform = `translate3d(${left}px,${top}px,0)`
                    oDiv.style.top = top + 'px'// 重新设置元素位置
                    oDiv.style.left = left + 'px'
                }

                // document.onmousemove = this.throttle(moveFn, 17).bind(this)
                document.onmousemove = function (e) {
                    window.requestAnimationFrame(function () {
                        moveFn(e)
                    })
                }

                oDiv.onmouseup = function () { // 鼠标抬起事件
                    document.onmousemove = null// 清除鼠标按下事件
                    oDiv.onmouseup = null
                }
            }.bind(this)
        }
    }
}
</script>


<style lang='scss'>
@use 'sass:math';
@import '@assets/styles/varibles.scss';

$resize-span-size: 10px; //拖拽div的宽高
$resize-span-move-size: math.div(-$resize-span-size, 2); //需要移动的距离

.drag-resize-box {
    position: fixed;
    top: 100px;
    left: 100px;
    //transform: translate3d(0, 0, 0);
    width: 100px;
    height: 100px;
    z-index: 1111;
    background: #fff;
    border: 2px solid #e8e8e8;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, .3);
    padding: 20px;
    cursor: pointer;
    .t,
    .b,
    .l,
    .r {
        position: absolute;
        z-index: 1;
    }

    .l,
    .r {
        width: $resize-span-size;
        height: 100%;
        cursor: w-resize;
    }

    .t,
    .b {
        width: 100%;
        height: 10px;
        cursor: n-resize;
    }

    .t {
        top: $resize-span-move-size;
    }

    .b {
        bottom: -$resize-span-size
    }

    .l {
        left: $resize-span-move-size;
    }

    .r {
        right: -$resize-span-size
    }

    .tl,
    .bl,
    .br,
    .tr {
        width: 15px;
        height: 15px;
        position: absolute;
        z-index: 2;
        cursor: nwse-resize;
        border-radius: 50%;
    }

    .tl,
    .bl {
        left: -5px
    }

    .tr,
    .br {
        right: -5px
    }

    .br,
    .bl {
        bottom: -5px
    }

    .tl,
    .tr {
        top: -5px
    }

    .tr,
    .bl {
        cursor: nesw-resize
    }

    ::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
    }
}


</style>
