<!-- 所有财报表格公共组件 -->
<template>
    <div class="financial-report-info-page">
        <tabs-panel
            :tabs-data="tabsData"
            :default-active-key="defaultActiveKey"
            @handle-tabs-change="handleTabsChange" />
        <div class="financial-report-info-page__content">
            <component :is="defaultActiveKey" />
        </div>
    </div>
</template>

<script>
import TabsPanel from '@components/TabsPanel'

export default {
    name: 'FinancialInfos',
    components: {
        TabsPanel,
        BalanceSheetPanel: () => import('./BalanceSheetPanel'),
        CashFlowStatementPanel: () => import('./CashFlowStatementPanel'),
        FinancialAnalysis: () => import('./FinancialAnalysis'),
        ProfitStatementPanel: () => import('./ProfitStatementPanel'),
        PropertyImagesPanel: () => import('./PropertyImagesPanel')
    },
    data() {
        return {
            tabsData: [
                {
                    key: 'BalanceSheetPanel',
                    tab: '资产负债表'
                },
                {
                    key: 'ProfitStatementPanel',
                    tab: '利润表'
                },
                {
                    key: 'CashFlowStatementPanel',
                    tab: '现金流量表'
                },
                {
                    key: 'FinancialAnalysis',
                    tab: '财务指标分析表'
                },
                {
                    key: 'PropertyImagesPanel',
                    tab: '资料影像'
                }
            ],
            defaultActiveKey: 'BalanceSheetPanel'
        }
    },
    methods: {
        /**
         * tab改变
         */
        handleTabsChange(key) {
            this.defaultActiveKey = key
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.financial-report-info-page {
    .ant-card-head {
        background: rgba(0, 0, 0, 0.02);
    }


    .ant-tabs {
        margin-top: 10px;
    }

    .ant-table-title {
        text-align: center;
        background: #fafafa;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 1px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
    }

    .common-row {
        flex-wrap: nowrap;
        height: calc(100vh - 430px);
        overflow: scroll;

        div {
            white-space: pre-wrap;
            word-wrap: break-word;
            flex-shrink: 0;
            min-width: 200px;
        }
    }

    .common-title {
        text-align: center;
        margin: 14px 0;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0f0f0f;
    }

    .common-description {
        padding-right: $ant-card-body-padding;

        .ant-descriptions-item:nth-child(2) {
            text-align: right;
        }
    }
}
</style>
