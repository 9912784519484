<!-- 数据核验模型-数据核验模型详情-基本信息 -->
<template>
    <div>
        <audit-state
            :info="info" />
        <container-panel class="basic-info-tab-panel">
            <descriptions-with-head title="基本信息">
                <a-button
                    v-if="canAction"
                    slot="button"
                    v-auth="RULE_ENGINE_DATA_CHECK_MODEL_EDIT_BASIC_INFO_BUTTON"
                    type="primary"
                    @click="handleEdit">
                    编辑
                </a-button>
                <a-descriptions bordered :column="1">
                    <a-descriptions-item label="数据核验模型名称">
                        {{ empty(info.modelExamplesName) }}
                    </a-descriptions-item>
                    <a-descriptions-item label="模型描述">
                        {{
                            empty(info.description)
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="业务类型">
                        {{
                            empty(BUSINESS_TYPE_MAP[info.riskBusinessType])
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="产品分类">
                        {{
                            empty(info.riskCategoryName)
                        }}
                    </a-descriptions-item>
                </a-descriptions>
            </descriptions-with-head>
        </container-panel>
        <add-or-edit-data-check-modal
            mode="edit"
            :visible="visible"
            :info="info"
            @success="handleSuccess"
            @cancel="handleCancel"
        />
    </div>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import { Descriptions as ADescriptions, Button as AButton } from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import AddOrEditDataCheckModal from '@views/RuleEngine/DataCheckConfig/DataCheckList/AddOrEditDataCheckModal'
import AuditState from '@weights/AuditState'

import { RULE_ENGINE_DATA_CHECK_MODEL_EDIT_BASIC_INFO_BUTTON } from '@constant/authEnum/ruleEngine'
import { BUSINESS_TYPE_MAP } from '@constant/enum'

import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'
import showModelButton from '@utils/showModelButton'
export default {
    name: 'BasicInfoTabPanel',
    components: {
        ContainerPanel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        DescriptionsWithHead,
        AButton,
        AddOrEditDataCheckModal,
        AuditState
    },
    mixins: [empty, enumOperate],
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            BUSINESS_TYPE_MAP,
            RULE_ENGINE_DATA_CHECK_MODEL_EDIT_BASIC_INFO_BUTTON
        }
    },
    computed: {
    // 可操作
        canAction() {
            const { isLook, info } = this
            const canAction = showModelButton.canEdit(info.auditState)
            return !isLook && canAction
        }
    },
    methods: {
        handleSuccess() {
            this.visible = false
            this.$emit('success')
        },
        handleCancel() {
            this.visible = false
        },
        handleEdit() {
            this.visible = true
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.basic-info-tab-panel {
  .ant-descriptions-item-label {
    width: $descriptions-item-label-width;
    padding: $descriptions-item-label-padding;
  }
}
</style>
