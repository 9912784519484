<!-- 企业详情-基本信息-额度信息 -->
<template>
    <a-descriptions bordered :column="1" class="enterprise-quota-information-panel">
        <a-descriptions-item label="累计保额（元）">{{ info.accumulatedInsuredAmount }}</a-descriptions-item>
    </a-descriptions>
</template>

<script>
import { Descriptions as ADescriptions } from 'ant-design-vue'
import empty from '@mixins/empty'

export default {
    name: 'EnterpriseQuotaInformationPanel',
    components: {
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    mixins: [empty],
    props: {
        info: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {},
    methods: {}
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
.enterprise-quota-information-panel {
  .ant-descriptions-item-label {
    width: $descriptions-item-label-width;
  }
}
</style>
