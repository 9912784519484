import uploadFileService from '@service/upLoadFileService'
import { getAcceptFromEnum, isAcceptFile } from '@utils/file'
export default {
    props: [
        'directory',
        'data',
        'disabled',
        'headers',
        'multiple',
        'name',
        'payload',

        'type',
        'fileList',
        'autoUpload',
        'handleBeforeUpload'
    ],
    data() {
        return {}
    },
    computed: {
        props() {
            const { $props, accept } = this
            const _props = {
                ...$props
            }
            if (!_props.fileList) {
                delete _props.fileList
            }

            const props = {
                customRequest: this.uploadFile,
                beforeUpload: this.beforeUpload,
                ..._props,
                accept
            }
            return props
        },
        listeners() {
            return this.$listeners
        },
        // 上传组件文件校验参数 '.jpg,png'
        accept() {
            return getAcceptFromEnum(this.acceptList)
        }
    },
    methods: {
        async uploadFile({ onSuccess, onError, file }) {
            try {
                const params = new FormData()
                params.append('multipartFile', file)
                params.append('type', this.ossType)
                const url = await uploadFileService(params)
                onSuccess(url)
                let emitValue = ''
                if (typeof this.value === 'string') {
                    emitValue = url
                } else if (typeof this.value === 'object') {
                    emitValue = {
                        documentName: file.name,
                        documentAddr: url
                    }
                }
                this.file = file
                this.$emit('input', emitValue)
                this.$emit('blur')
            } catch (e) {
                onError()
                this.$message.error(e.message)
            }
        },
        beforeUpload(file) {
            const { size, autoUpload, handleBeforeUpload, acceptList } = this
            if (handleBeforeUpload && handleBeforeUpload(file) === false) {
                return false
            }
            // 得到文件的后缀名
            const fileExt = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase()
            if (!isAcceptFile(fileExt, acceptList)) {
                this.$message.error(`不支持上传${fileExt}格式文件`)
                return false
            }
            if (size) {
                const isLt = file.size < size * 1024 * 1024
                if (!isLt) {
                    this.$emit('validate', {
                        type: 'FILE_LARGE',
                        size
                    })
                    this.$message.error(`文件不能超过${size}M`)
                    return false
                }
            }
            if (autoUpload === false) {
                return false
            }
            return true
        }
    }
}
