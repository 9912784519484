<!-- 数据核验模型-数据核验模型详情-布局分类 -->
<template>
    <container-panel :no-card-body-padding-top="true">
        <template slot="title">布局分类</template>
        <custom-button
            v-show="canAdd"
            slot="button"
            v-auth="RULE_ENGINE_DATA_CHECK_MODEL_ADD_LAYOUT_CATEGORY_BUTTON"
            text="添加布局分类"
            type="reset"
            @click="handleAdd" />
        <!-- 表格 start -->
        <template slot="content" slot-scope="">
            <a-table :columns="columns"
                     :data-source="dataList"
                     :pagination="pagination"
                     :loading="loading"
                     row-key="id"
                     @change="handleTableChange">
                <template slot="index" slot-scope="text,action,index">{{ index + 1 }}</template>
                <template
                    v-if="canAction"
                    slot="action"
                    slot-scope="text,action">
                    <a-button
                        v-auth="RULE_ENGINE_DATA_CHECK_MODEL_EDIT_LAYOUT_CATEGORY_BUTTON"
                        type="link"
                        @click="handleEdit(action)">
                        编辑
                    </a-button>
                    <a-button
                        v-auth="RULE_ENGINE_DATA_CHECK_MODEL_DELETE_LAYOUT_CATEGORY_BUTTON"
                        type="link"
                        @click="handleDelete(action)">
                        删除
                    </a-button>
                </template>
            </a-table>
        </template>
        <!-- 表格 end -->
        <add-or-edit-layout-category-modal
            :mode="mode"
            :visible="visible"
            :info="record"
            @cancel="handleCancel"
            @success="handleSuccess" />
    </container-panel>
</template>

<script>
// 组件
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import AddOrEditLayoutCategoryModal from './AddOrEditLayoutCategoryModal'
import ContainerPanel from '@components/ContainerPanel'
import CustomButton from '@components/Button'
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    RULE_ENGINE_DATA_CHECK_MODEL_EDIT_LAYOUT_CATEGORY_BUTTON,
    RULE_ENGINE_DATA_CHECK_MODEL_DELETE_LAYOUT_CATEGORY_BUTTON,
    RULE_ENGINE_DATA_CHECK_MODEL_ADD_LAYOUT_CATEGORY_BUTTON
} from '@constant/authEnum/ruleEngine'
import {
    DATA_CHECK_MODEL_LAYOUT_TYPE
} from '@constant/enum'
import showModelButton from '@utils/showModelButton'

import getLayoutCategoryListService from '@service/ruleEngine/DataCheckSetting/getLayoutCategoryListService'
import deleteLayoutCategoryService from '@service/ruleEngine/DataCheckSetting/deleteLayoutCategoryService'
import { ERROR_COMMIT_DELETE } from '@constant/error'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'left',
        width: 150,
        scopedSlots: {
            customRender: 'index'
        }
    }, {
        title: '布局分类名称',
        dataIndex: 'name',
        width: 600,
        align: 'left',
        key: 'name'
    }, {
        title: '操作',
        dataIndex: 'action',
        align: 'left',
        key: 'action',
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'LayoutCategoryTabPanel',
    components: {
        AddOrEditLayoutCategoryModal,
        CustomButton,
        ContainerPanel,
        ATable,
        AButton
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            mode: '',
            record: {},
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            RULE_ENGINE_DATA_CHECK_MODEL_DELETE_LAYOUT_CATEGORY_BUTTON,
            RULE_ENGINE_DATA_CHECK_MODEL_EDIT_LAYOUT_CATEGORY_BUTTON,
            RULE_ENGINE_DATA_CHECK_MODEL_ADD_LAYOUT_CATEGORY_BUTTON
        }
    },
    computed: {
        pagination() {
            const { total, limit, current } = this
            return {
                showTotal: (total, range) => `第${range[0]}-${range[1]}条/总共${total}条`,
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        queryId() {
            return this.$route.query.id
        },
        columns() {
            const { canAction } = this
            if (canAction) {
                return columns
            } else {
                const len = columns.length
                return columns.slice(0, len - 1)
            }
        },
        // 可操作
        canAction() {
            const { isLook, info } = this
            const canAction = showModelButton.canEdit(info.auditState)
            return !isLook && canAction
        },
        // 可添加
        canAdd() {
            const { isLook, info } = this
            return !isLook && showModelButton.canAdd(info.auditState)
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleAdd() {
            this.visible = true
            this.mode = 'create'
        },
        handleEdit(record) {
            this.visible = true
            this.mode = 'edit'
            this.record = record
        },
        handleDelete(action) {
            this.$confirm({
                title: '确认删除当前布局分类吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteService(action.id)
                },
                onCancel() {
                }
            })
        },
        handleCancel() {
            this.visible = false
        },
        handleSuccess() {
            this.visible = false
            this.getList()
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async deleteService(id) {
            try {
                await deleteLayoutCategoryService(id)
                this.$message.success(ERROR_COMMIT_DELETE)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, queryId } = this
                const param = {
                    current,
                    size: limit,
                    objectId: queryId,
                    layoutType: DATA_CHECK_MODEL_LAYOUT_TYPE
                }
                const result = await getLayoutCategoryListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">

</style>
