<template>
    <form-create
        v-model="fApi"
        :value.sync="formValue"
        :rule="rule"
        :option="options"
        class="form-create" />
</template>

<script>
import formCreate from '@form-create/ant-design-vue'
import {
    FormModel,
    Row,
    Col,
    Button,
    Rate,
    Input,
    Select,
    Cascader,
    DatePicker,
    Radio,
    InputNumber,
    Icon,
    Popover,
    AutoComplete
} from 'ant-design-vue'
import AreaCom from './AreaCom'
import ProjectType from '@weights/GbCloud/SubmitOrder/ProjectType'
import ConstructionQualification from '@weights/GbCloud/SubmitOrder/ConstructionQualification'
import DictSelect from '@weights/GbCloud/DictSelect'
import DictCascader from '@weights/GbCloud/DictCascader'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import UploadInOrder from '@weights/AppUpload/UploadInOrder'
import CustomAutoComplete from '@views/RiskControlProcurement/SubmitOrder/CustomAutoComplete'
import GetCreditCode from '@views/RiskControlProcurement/SubmitOrder/GetCreditCode'

formCreate.component('AFormModel', FormModel)
formCreate.component('AFormModelItem', FormModel.Item)
formCreate.component('ARow', Row)
formCreate.component('ACol', Col)
formCreate.component('AButton', Button)
formCreate.component('ARate', Rate)
formCreate.component('AInput', Input)
formCreate.component('AInputNumber', InputNumber)
formCreate.component('ASelect', Select)
formCreate.component('ACascader', Cascader)
formCreate.component('ADatePicker', DatePicker)
formCreate.component('ARangePicker', DatePicker.RangePicker)
formCreate.component('ARadioGroup', Radio.Group)
formCreate.component('AIcon', Icon)
formCreate.component('APopover', Popover)
formCreate.component('AAutoComplete', AutoComplete)

// 自定义组件
formCreate.component('AreaCascader', AreaCom)
formCreate.component('DictSelect', DictSelect)
formCreate.component('DictCascader', DictCascader)
formCreate.component('DescriptionsWithHead', DescriptionsWithHead)
formCreate.component('UploadInOrder', UploadInOrder)
formCreate.component('ProjectType', ProjectType)
formCreate.component('ConstructionQualification', ConstructionQualification)
formCreate.component('CustomAutoComplete', CustomAutoComplete)
formCreate.component('GetCreditCode', GetCreditCode)
export default {
    name: 'ComFormCreate',
    components: {
        FormCreate: formCreate.$form()
    },
    props: {
        rule: {
            type: Array,
            default() {
                return []
            }
        },
        value: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            fApi: {},
            options: {
                submitBtn: false,
                formData: {}
            }
        }
    },
    computed: {
        formValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        submit() {
            let res = null
            this.fApi.submit(formData => {
                res = formData
            })
            return res
        },
        reset() {
            this.fApi.resetFields()
        }
    }
}
</script>

<style lang='scss'>
</style>
