<!-- 风控订单 上传oss -->
<template>
    <div class="upload-in-order">
        <a-upload
            v-if="!hasValue"
            class="upload-in-order__upload"
            v-bind="props"
            v-on="listeners">
            <a-button icon="upload">上传</a-button>
            <span style="color: #ddd; font-size: 14px; line-height: 30px; margin-left: 5px">(仅支持格式：zip,doc,docx,xls,xlsx,pdf,jpg,png)</span>
        </a-upload>
        <div v-else class="upload-in-order__file-name" @click="handlePreview">
            <div>
                <a-icon type="link" />
                {{ val.documentName }}
            </div>
            <a-button type="link"
                      icon="delete"
                      @click.stop="handleDelete" />
            <!--    pdf预览 -->
            <iframe-modal
                :url="val.documentAddr"
                :visible="previewVisible"
                @cancel="previewVisible=false" />
            <!--    图片预览 -->
            <a-modal :visible="imgVisible" :footer="null" @cancel="imgVisible=false">
                <img alt="example" style="width: 100%" :src="val.documentAddr">
            </a-modal>
        </div>
    </div>
</template>

<script>
import {
    Upload as AUpload,
    Button as AButton,
    Icon as AIcon
} from 'ant-design-vue'
import mixins from './mixins'
import { getFileExt, isImg, isPdf, IS_FILE_ENUM_LIST } from '@utils/file'
import { OSS_PACKAGE_TYPE_RISK_STOCK_TYPE } from '@constant/enum'
import { OSS_PACKAGE_TYPE_RISK_STOCK_MAX_SIZE } from '@constant/Const'

export default {
    name: 'UploadView',
    components: {
        AUpload,
        AButton,
        IframeModal: () => import('@weights/IframeModal'),
        AIcon
    },
    mixins: [mixins],
    props: {
        // {documentName:'',documentAddr:''}
        value: {
            type: [Object, String],
            default() {
                return {}
            }
        },
        ossType: {
            type: Number,
            default: OSS_PACKAGE_TYPE_RISK_STOCK_TYPE
        },
        size: {
            type: Number,
            default: OSS_PACKAGE_TYPE_RISK_STOCK_MAX_SIZE
        },
        // 支持上传的文件格式数组
        acceptList: {
            type: Array,
            default() {
                return IS_FILE_ENUM_LIST
            }
        }
    },
    data() {
        return {
            imgVisible: false,
            previewVisible: false,
            val: {}
        }
    },
    computed: {
        hasValue() {
            const { val } = this
            return val && val.documentAddr
        }
    },
    watch: {
        value: {
            handler(v) {
                if (v && typeof v === 'string') { // 编辑或者重新下单 后台数据赋值的是json 此处做特殊处理
                    this.val = JSON.parse(v)
                } else {
                    this.val = v || null
                }
                this.$emit('input', this.val) // 保证form校验对象
            },
            immediate: true
        }
    },
    methods: {
        handlePreview() {
            const txt = getFileExt(this.val.documentName)
            if (isImg(txt)) {
                this.imgVisible = true
            } else if (isPdf) {
                this.previewVisible = true
            } else {
                window.open(this.val.documentAddr)
            }
        },
        handleDelete() {
            this.$emit('input', {})
            this.$emit('blur')
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
@import '@assets/styles/mixins.scss';

.upload-in-order {
    &__upload .ant-btn {
        padding: 0 30px;
        border: 1px solid $custom-button-reset-border-color;
        color: $custom-button-reset-text-color;
    }

    &__file-name {
        cursor: pointer;
        display: flex;
        align-items: center;

        > div {
            max-width: 350px;
            @include ellipsis();
        }
    }
}

//.ant-form-explain{
//  position: relative;
//  top: -30px;
//  z-index: 2;
//  right: -120px;
//}
</style>
