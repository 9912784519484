<!-- 企业管理-企业列表-征信报告 -->
<template>
    <div>
        <single-search-panel :model="form" @search="handleSearch">
            <form-col v-bind="tabLayout" label="搜索" prop="riskControlNumber">
                <template slot="formElements">
                    <a-input v-model="form.riskControlNumber" placeholder="输入风控单号搜索" />
                </template>
            </form-col>
        </single-search-panel>
        <a-table
            :columns="columns"
            :data-source="dataList"
            :pagination="pagination"
            :loading="loading"
            :scroll="scrollConfig"
            row-key="riskControlNumber"
            @change="handleTableChange">
            <!-- 表格的序号递增 -->
            <template slot="sort" slot-scope="text, record,index">{{ index + 1 }}</template>
            <template slot="action" slot-scope="action">
                <a-button
                    v-auth="ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_CREDIT_REPORT_BUTTON"
                    type="link"
                    @click="handleDeatil(action)"
                >
                    查看征信报告
                </a-button>
            </template>
        </a-table>
    </div>
</template>
<script >
import {
    Button as AButton,
    Table as ATable,
    Input as AInput
} from 'ant-design-vue'
import SingleSearchPanel from '@components/SingleSearchPanel'
import FormCol from '@components/FormCol'

import { ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_CREDIT_REPORT_BUTTON } from '@constant/authEnum/enterprise'
import { DEFAULT_PAGES_SIZE } from '@config'

import tableShowTotal from '@mixins/tableShowTotal'
import getCreditReportListService from '@service/enterprise/getCreditReportListService'
import { filterParams } from '@utils/search'
const columns = [
    {
        title: '序号',
        dataIndex: 'sort',
        align: 'left',
        key: 'sort',
        fixed: 'left',
        width: 100,
        scopedSlots: {
            customRender: 'sort'
        }
    },
    {
        title: '风控单号',
        dataIndex: 'riskControlNumber',
        align: 'left',
        key: 'riskControlNumber',
        scopedSlots: {
            customRender: 'riskControlNumber'
        },
        width: 300
    },
    {
        title: '征信报告日期',
        dataIndex: 'creditDate',
        align: 'left',
        key: 'creditDate',
        width: 300
    },
    {
        title: '提交时间',
        dataIndex: 'createDateTime',
        align: 'left',
        key: 'createDateTime',
        width: 300
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        width: 350,
        fixed: 'right'
    }
]

export default {
    name: 'CreditReportInformationPanel',
    components: {
        AButton,
        ATable,
        SingleSearchPanel,
        AInput,
        FormCol
    },
    mixins: [tableShowTotal],
    props: {
        tabLayout: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            columns,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100,
                y: 500
            },
            ENTERPRISE_MANAGE_ENTERPRISE_INFO_LOOK_CREDIT_REPORT_BUTTON,
            form: {
                riskControlNumber: ''
            }
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        enterpriseId() {
            return this.$route.query.id
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch() {
            this.current = 1
            // this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        handleExport() {},
        handleDeatil(action) {
            const { enterpriseId } = this
            const { riskControlNumber } = action
            this.$router.push({
                name: 'CreditReportInfoIndex',
                query: {
                    id: enterpriseId,
                    riskControlNumber
                }
            })
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, form, enterpriseId } = this
                const param = {
                    current,
                    size: limit,
                    ...form,
                    enterpriseId
                }
                const result = await getCreditReportListService(filterParams(param))
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss">
</style>
