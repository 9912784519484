<!-- 企业详情-财报信息详情-资产负债表 -->
<template>
    <div class="balance-sheet-panel">
        <div class="common-title">资产负债表</div>
        <a-descriptions layout="horizontal" :column="2" class="common-description">
            <a-descriptions-item label="企业名称">{{ info.enterpriseName }}</a-descriptions-item>
            <a-descriptions-item label="单位">元</a-descriptions-item>
        </a-descriptions>
        <a-row v-if="queryList.length"
               type="flex"
               class="common-row">
            <a-col flex="450px">
                <div class="balance-sheet-panel__border-bottom balance-sheet-panel__thead">项目</div>
                <div class="balance-sheet-panel__border-bottom balance-sheet-panel__thead">资产</div>
                <div
                    v-for="(item,index) in leftArr[0]"
                    :key="index+leftArr[0].length"
                    class="balance-sheet-panel__border-bottom">
                    {{ item.name }}
                </div>
            </a-col>

            <a-col
                v-for="(item,index) in leftArr"
                :key="index"
                :flex="1">
                <template v-if="item">
                    <div class="balance-sheet-panel__border-bottom balance-sheet-panel__thead">
                        <!--           2020年 -->
                        {{ showYearText(queryList[index].reportDate) }}
                    </div>
                    <div class="balance-sheet-panel__border-bottom balance-sheet-panel__thead">
                        {{ dateToText(queryList[index].reportDate, index, leftArr) }}
                    </div>
                    <div v-for="item2 in item"
                         :key="item2.coding"
                         class="balance-sheet-panel__border-bottom">
                        {{ item2.closingingBalance }}
                    </div>
                </template>
            </a-col>

            <a-col flex="450px">
                <div class="balance-sheet-panel__border-bottom balance-sheet-panel__thead">科目</div>
                <div class="balance-sheet-panel__border-bottom balance-sheet-panel__thead">负债和所有者权益(或股东权益)</div>
                <div
                    v-for="item in rightArr[0]"
                    :key="item.coding"
                    class="balance-sheet-panel__border-bottom">
                    {{ item.name }}
                </div>
            </a-col>
            <a-col
                v-for="(item,index) in rightArr"
                :key="index+Math.random()"
                :flex="1">
                <template v-if="item">
                    <div class="balance-sheet-panel__border-bottom balance-sheet-panel__thead">
                        <!--  2018 -->
                        {{ showYearText(queryList[index].reportDate) }}
                    </div>
                    <div class="balance-sheet-panel__border-bottom balance-sheet-panel__thead">
                        {{ dateToText(queryList[index].reportDate, index, rightArr) }}
                    </div>
                    <div v-for="item2 in item"
                         :key="item2.coding"
                         class="balance-sheet-panel__border-bottom">
                        {{ item2.closingingBalance }}
                    </div>
                </template>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import {
    Row as ARow,
    Col as ACol,
    Descriptions as ADescriptions
} from 'ant-design-vue'

import empty from '@mixins/empty'

import getBalanceSheetService from '@service/enterprise/getBalanceSheetService'

const columns = [
    {
        title: '科目',
        align: 'left',
        children: [
            {
                title: '资产',
                dataIndex: 'property',
                align: 'left',
                key: 'property',
                scopedSlots: {
                    customRender: 'property'
                },
                width: 300
            }
        ]
    },
    {
        title: '2017年',
        align: 'left',
        children: [
            {
                title: '期末余额',
                dataIndex: 'endOfTermBalance',
                align: 'left',
                key: 'endOfTermBalance',
                scopedSlots: {
                    customRender: 'endOfTermBalance'
                },
                width: 200
            }
        ]
    },
    {
        title: '2018年',
        align: 'left',
        children: [
            {
                title: '期末余额',
                dataIndex: 'endOfTermBalance2018',
                align: 'left',
                key: 'endOfTermBalance2018',
                scopedSlots: {
                    customRender: 'endOfTermBalance2018'
                },
                width: 200
            }
        ]
    },
    {
        title: '2019年',
        align: 'left',
        children: [
            {
                title: '期末余额',
                dataIndex: 'endOfTermBalance2019',
                align: 'left',
                key: 'endOfTermBalance2019',
                scopedSlots: {
                    customRender: 'endOfTermBalance2019'
                },
                width: 200
            }
        ]
    },
    {
        title: '2020年',
        align: 'left',
        children: [
            {
                title: '期末余额',
                dataIndex: 'endOfTermBalance2020',
                align: 'left',
                key: 'endOfTermBalance2020',
                scopedSlots: {
                    customRender: 'endOfTermBalance2020'
                },
                width: 200
            }
        ]
    },
    {
        title: '科目',
        align: 'left',
        width: '300',
        children: [
            {
                title: '负债和所有者权益(或股东权益）',
                dataIndex: 'liabilities',
                align: 'left',
                key: 'liabilities',
                scopedSlots: {
                    customRender: 'liabilities'
                },
                width: 300
            }
        ]
    },
    {
        title: '2017年',
        align: 'left',
        children: [
            {
                title: '期末余额',
                dataIndex: 'liabilitiesEndOfTermBalance',
                align: 'left',
                key: 'liabilitiesEndOfTermBalance',
                scopedSlots: {
                    customRender: 'liabilitiesEndOfTermBalance'
                },
                width: 200
            }
        ]
    },
    {
        title: '2018年',
        align: 'left',
        children: [
            {
                title: '期末余额',
                dataIndex: 'liabilitiesEndOfTermBalance2',
                align: 'left',
                key: 'liabilitiesEndOfTermBalance2',
                scopedSlots: {
                    customRender: 'liabilitiesEndOfTermBalance2'
                },
                width: 200
            }
        ]
    },
    {
        title: '2019年',
        align: 'left',
        children: [
            {
                title: '期末余额',
                dataIndex: 'liabilitiesEndOfTermBalanc3',
                align: 'left',
                key: 'liabilitiesEndOfTermBalanc3',
                scopedSlots: {
                    customRender: 'liabilitiesEndOfTermBalanc3'
                },
                width: 200
            }
        ]
    },
    {
        title: '2020年',
        align: 'left',
        fixed: 'right',
        children: [
            {
                title: '期末余额',
                dataIndex: 'liabilitiesEndOfTermBalance4',
                align: 'left',
                key: 'liabilitiesEndOfTermBalance4',
                scopedSlots: {
                    customRender: 'liabilitiesEndOfTermBalance4'
                },
                width: 200,
                fixed: 'right'
            }
        ]
    }
]

export default {
    name: 'BalanceSheetPanel',
    components: {
        ARow,
        ACol,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    mixins: [empty],
    data() {
        return {
            columns,
            queryList: [],
            info: {},
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100
            },
            leftArr: [],
            rightArr: []
        }
    },
    computed: {
        id() {
            return this.$route.query.id
        },
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    watch: {
        queryList(val) {
            val.forEach(item => {
                const [left, right] = this.financeData(item.queryList)
                this.leftArr.push(left)
                this.rightArr.push(right)
            })
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        // 依据日期 显示下面的文本
        dateToText(date, index, arr) {
            const len = arr.length
            if (index === len - 1) {
                return '本期'
            } else {
                return '期末余额'
            }
        },
        showYearText(date) {
            if (date.includes('.')) {
                const [year, mon] = date.split('.')
                return `${year}年${mon}月`
            } else {
                return `${date}年`
            }
        },
        // 数据分边
        financeData(financeAddBOList) {
            const sum = financeAddBOList[financeAddBOList.length - 1].sort // 总数（带空的）
            const middle = sum / 2 // 界限索引
            let arr = []
            let index = 0
            for (let i = 1; i <= sum; i++) {
                if (financeAddBOList.find(item => item.sort === i)) {
                    arr.push(financeAddBOList[index++])
                } else {
                    arr.push('')
                }
            }
            return [arr.splice(0, middle), arr]
        },
        async getList() {
            const { riskControlNumber } = this
            try {
                this.loading = true
                this.info = await getBalanceSheetService({
                    riskControlNumber
                })
                this.queryList = this.info.queryList || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.balance-sheet-panel {

    .ant-col {
        text-indent: 25px;
        line-height: 44px;
    }

    &__thead {
        background-color: $custom-table-title-background-color;
        line-height: 44px;
    }

    .ant-table-wrapper {
        margin-bottom: 35px;
    }


    &__border-bottom {
        border-bottom: 1px solid #e8e8e8;
    }
}
</style>
