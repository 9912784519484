<!-- 评分因子类型-基本类型 -->
<template>
    <a-form-model
        ref="ruleForm"
        :model="form"
        class="grade-factor-basic-type-form">
        <table>
            <thead class="grade-factor-basic-type-form__thead">
                <tr>
                    <th
                        v-for="item in basicTypeTab"
                        :key="item.name"
                        :style="{width:item.width+'px',textAlign:'left',padding:'0 20px'}"
                        class="ant-table-align-center">
                        {{ item.name }}
                    </th>
                </tr>
            </thead>
            <tbody class="grade-factor-basic-type-form__tbody">
                <tr v-for="(item,index) in form.scoringFactorDetails" :key="item.value">
                    <td>
                        <a-form-model-item>{{ index + 1 }}</a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item>{{ item.name }}</a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item
                            :prop="'scoringFactorDetails.' + index + '.score'"
                            :rules="[{
                                required: true,
                                message: '请输入分值',
                                trigger: 'blur',
                            },{validator:validateNegativeAttribute}]">
                            <a-input
                                v-model="item.score"
                                placeholder="输入分值"
                                type="init"
                                style="width: 100px" />
                        </a-form-model-item>
                    </td>
                </tr>
            </tbody>
        </table>
    </a-form-model>
</template>

<script>
import { Input as AInput, FormModel as AFormModel } from 'ant-design-vue'

import {
    GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE,
    RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE,
    RISK_FACTOR_DATA_TYPE_DICT_TYPE
} from '@constant/enum'
import { validateAttribute, validateNegativeAttribute } from '@constant/validates'
import getAttributesService from '@service/gbCloud/getAttributesService'
export default {
    name: 'GradeFactorBasicType',
    components: {
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    props: {
    // 详情数据 修改使用
        factorRecordInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        // 选择的因子获取数据
        factorDataItem: {
            type: Object,
            default() {
                return {}
            }
        },
        scoringFactorType: {
            type: Number
        }
    },
    data() {
        return {
            form: {
                scoringFactorDetails: []
            },
            score: '',
            basicTypeTab: [
                {
                    name: '序号',
                    width: 80,
                    align: 'center'
                }, {
                    name: '属性',
                    width: 150,
                    align: 'center'
                }, {
                    name: '分值',
                    width: 180,
                    align: 'center'
                }
            ],
            attributesData: [], // 属性值列表
            loading: false,
            validateAttribute,
            validateNegativeAttribute
        }
    },
    computed: {
        params() {
            const { form, scoringFactorType } = this
            let params = JSON.parse(JSON.stringify(form))
            params.scoringFactorDetails = params.scoringFactorDetails.map(item => ({
                score: item.score,
                value: item.value,
                scoringFactorType
            }))
            return params
        },
        attributesDataMap() {
            const { attributesData } = this
            let map = new Map()
            attributesData.forEach(item => {
                map.set(item.value, item)
            })
            return map
        }
    },
    watch: {
        factorDataItem: {
            handler() {
                this.getAttributes()
            },
            immediate: true
        },
        factorRecordInfo: {
            handler(val) {
                if (val.scoringFactorType === GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE) {
                    this.form.scoringFactorDetails = val.scoringFactorDetails
                }
            },
            immediate: true
        },
        attributesData: {
            handler(val) {
                const id = this.$route.query.id
                if (!id) { // 如果不是修改
                    this.form.scoringFactorDetails = val
                } else {
                    const { attributesDataMap, factorRecordInfo } = this
                    factorRecordInfo.scoringFactorDetails.forEach(item => {
                        item.name = attributesDataMap.get(item.value).name
                    })
                    this.form.scoringFactorDetails = [...factorRecordInfo.scoringFactorDetails]
                }
            }
        }
    },
    methods: {
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    res = this.params
                }
            })
            return res
        },
        resetFields() {
            this.form.scoringFactorDetails = []
        },
        /**
     * 获取属性值
     * */
        async getAttributes() {
            const { factorDataItem } = this
            const { dataType, dictionaryCategoryId, source } = factorDataItem
            // 指定dataType 才有属性值
            if (dataType !== RISK_FACTOR_DATA_TYPE_DICT_TYPE && dataType !== RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE) return
            try {
                this.loading = true
                this.attributesData = await getAttributesService({
                    dataType,
                    dictionaryCategoryId,
                    source
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';
.grade-factor-basic-type-form {
  &__tbody {
    td {
      text-align: left;
      padding: 0 20px;
    }
  }
  &__thead {
    background-color: $custom-table-title-background-color;
    tr {
      line-height: $custom-table-thead-height
    }
  }
}

</style>
